<template>
  <div class="hedge-fund">
    <coin-swiper/>
    <div class="hedge-main-content">
      <div class="left">
        <div class="wallet-container">
          <div class="header-list">
            <div class="item">
              <div class="up">
                <img src="../../assets/images/tvl.png" alt="">
                <span class="title">Total Value Locked</span>
              </div>
              <span class="desc">{{ overallStats.totalValueLocked }}</span>
            </div>
            <div class="item">
              <div class="up">
                <img src="../../assets/images/money-bag.png" alt="">
                <span class="title">Unrealized PNL</span>
              </div>
              <span class="desc"><span :class="(overallStats.unrealizedPL>=0?'active':'passive')">{{
                  overallStats.unrealizedPL
                }}%</span></span>
            </div>
            <div class="item">
              <div class="up">
                <img src="../../assets/images/amount.png" alt="">
                <span class="title">Your profit</span>
              </div>
              <span class="desc">{{ userProfit }}</span>
            </div>
          </div>
          <div class="fond-amount">
            <div class="up">
              <div class="image-container">
                <img src="../../assets/images/fortchain_new.png" class="image" alt="">
              </div>
              <span class="amount">{{ totalBalance }} <span>DFORT</span></span>
            </div>
            <span class="description">{{ $tc('yourBalance') }}</span>
          </div>
          <!--          <div class="create-fond">-->
          <!--            <div class="create-inner">-->
          <!--              <div class="item">-->
          <!--                <span class="description">{{ $tc('writeInvestment') }}</span>-->
          <!--                <input v-model="fundAmount" type="number" placeholder="10000 DFORT" class="input">-->
          <!--              </div>-->
          <!--              <div class="item">-->
          <!--                &lt;!&ndash;              <span class="description">{{ $tc('confirmInvestment') }}</span>&ndash;&gt;-->
          <!--                <Button :disable="disableCreateFund"-->
          <!--                        :event="createNewInvestment"-->
          <!--                        :successEvent="investmentCreateResponseEvent"-->
          <!--                        :errorEvent="investmentCreateResponseEvent"-->
          <!--                        title="Deposit"/>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <Button :disable="disableWithdrawButton"-->
          <!--                    :event="withdrawWallet"-->
          <!--                    :successEvent="investmentCreateResponseEvent"-->
          <!--                    :errorEvent="investmentCreateResponseEvent"-->
          <!--                    title="Withdraw"/>-->
          <!--          </div>-->
        </div>
        <fund-table/>
      </div>
      <div class="right">
        <div id="chart" class="chart-box"></div>
        <div id="chart_pie" class="chart-box"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import Button from "../Button";
import {mapActions, mapGetters} from "vuex";
import * as echarts from 'echarts';
import FundTable from "./FundTable";
import CoinSwiper from "./CoinSwiper";

export default {
  data() {
    return {
      fundAmount: '',
      chartInfo: null,
    }
  },
  components: {
    // Button,
    FundTable,
    CoinSwiper
  },
  computed: {
    ...mapGetters({
      distribution: 'funds/getDistribution',
      overallStats: 'funds/getOverallStats',
      totalInvestment: 'funds/getTotalInvestment',
      funds: 'funds/getFunds',
      fundRewards: 'funds/getFundRewards'
    }),
    totalBalance() {
      return this.totalInvestment?.find(item => item.balanceType === 'DFORT')?.amount
    },
    option() {
      return {
        title: {
          text: 'Profits',
          textStyle: {
            color: 'white',
          },
          top: '10px',
          left: '10px'
        },
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value) => `${value} %`
        },
        legend: false,
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: false,
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.chartInfo?.map(item => item.date),
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: 'Unrealized Sum',
            type: 'line',
            color: '#0000fe',
            data: this.chartInfo?.map(item => item.sum)
          },
          {
            name: 'Unrealized PL',
            type: 'line',
            color: '#f44336',
            data: this.chartInfo?.map(item => item.unrealizedPL)
          },
          {
            name: 'Realized Profit',
            type: 'line',
            color: '#5cb85c',
            data: this.chartInfo?.map(item => item.realizedProfit)
          },
        ]
      }
    },
    pieOption() {
      return {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: false,
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: this.distribution || {},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              fontSize: 16,
              color: 'white'
            },
          }
        ]
      }
    },
    disableCreateFund() {
      return this.fundAmount < 1
    },
    disableWithdrawButton() {
      return this.userProfit <= 0
    },
    userProfit() {
      return this.fundRewards.filter(item => item.status === 'PENDING')?.reduce((accumulator, object) => {
        return accumulator + object.reward;
      }, 0);
    }
  },
  methods: {
    ...mapActions({
      getFunds: 'funds/getFunds',
      createFundInvestment: 'funds/createFundInvestment',
      getDistribution: 'funds/getFundDistribution',
      getOverallStats: 'funds/getOverallStats',
      getTotalInvestment: 'funds/getTotalInvestment'
    }),
    floorBy(number) {
      return Math.floor(number * 10000) / 10000
    },
    setPieChart() {
      this.$nextTick(() => {
        let chartPie = document.getElementById('chart_pie');

        let myChartPie = echarts.init(chartPie);
        myChartPie.setOption(this.pieOption)
      })
    },
    setChart() {
      let chartDom = document.getElementById('chart');
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.option)
    },
    createNewInvestment() {
      return this.createFundInvestment({
        amount: this.fundAmount,
      })
    },
    investmentCreateResponseEvent() {
      this.fundAmount = 0
    },
    getChartData() {
      this.$axios.get(`api/v1/fund/pool/${this.funds?.[0].id}/unrealized-balance`).then(({data}) => {
        this.chartInfo = data?.map(item => {
          return {
            ...item,
            date: this.moment(item.date).format('DD/MM'),
            realizedProfit: this.floorBy(item.realizedProfit / item.amount * 100),
            unrealizedPL: this.floorBy(item.unrealizedPL / item.amount * 100),
            sum: this.floorBy((item.unrealizedPL + item.realizedProfit) / item.amount * 100)
          }
        })
        this.setChart()
      }).catch(() => {
      })
    },
    withdrawWallet() {
      this.fundRewards.filter(item => item.status === 'PENDING')?.forEach(item => {
        return this.$axios.post(`/api/v1/fund/rewards/${item.id}/withdraw`, {}, {
          openModal: true,
          title: 'withdraw request successfully created'
        }).then(() => {
          this.getTotalInvestment()
        })
      })

    }
  },
  async created() {
    await this.getFunds()
    let type = this.funds?.find(item => item.type === 'DEFAULT')

    this.getOverallStats(type?.id)
    this.getTotalInvestment()
    this.getChartData()
    this.getDistribution()?.then(() => {
      this.setPieChart()
    })
  }
}
</script>

<style lang="scss" scoped>
.hedge-fund {
  width: 100%;
  height: 100%;
  background: #1e1e1e;
  padding: 0 27px;
  @media only screen and (max-width: 1440px) and (min-width: 1024px) {
    padding: 0 19px;
  }
  @media only screen and (max-width: 1023px) {
    background: transparent;
    padding: 0;
  }

  .hedge-main-content {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @media only screen and (max-width: 1366px) {
      display: block;
    }
    @media only screen and (max-width: 1023px) {
      padding: 19px;
      margin-top: 10px;
    }

    .left {
      border: 1px solid rgba(250, 250, 250, .4);
      padding: 30px;
      border-radius: 15px;
      width: 60%;
      @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        padding: 15px;
      }
      @media only screen and (max-width: 1366px) {
        width: 100%;
      }
      @media only screen and (max-width: 1023px) {
        padding: 0;
        border: unset;
      }

      .wallet-container {
        @include center-children;
        flex-direction: column;
        padding: 25px;
        border-radius: 15px;
        background: rgba(0, 0, 0, .3);
        width: 100%;
        @media only screen and (max-width: 767px) {
          padding: 15px;
        }

        .header-list {
          display: flex;
          justify-content: space-between;
          width: 100%;
          @media only screen and (max-width: 500px) {
            display: block;
          }

          .item {
            color: #FFFFFF;
            @media only screen and (max-width: 500px) {
              margin-bottom: 15px;
            }

            .up {
              @include flex;

              img {
                width: 18px;
              }

              .title {
                margin-left: 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                color: rgba(250, 250, 250, .8);
              }

            }

            .desc {
              @include flex;
              font-weight: 500;
              font-size: 20px;
              margin-top: 10px;

              span {
                font-size: 20px;

                &.active {
                  color: green;
                }

                &.passive {
                  color: red;
                }
              }
            }
          }
        }

        .fond-amount {
          margin-top: 50px;

          .up {
            @include flex;

            .image-container {
              @include center-children;
              border: 2px solid #1280A0;
              border-radius: 50%;
              height: 40px;
              width: 40px;
              margin-right: 10px;

              img {
                width: 15px;
              }
            }

            .amount {
              display: flex;
              font-weight: 500;
              font-size: 26px;
              color: #FFFFFF;

              span {
                color: $base-color;
                margin-left: 5px;
              }
            }
          }

        }

        .description {
          color: rgba(250, 250, 250, .7);
          text-align: center;
          margin-top: 5px;
          font-size: 14px;
        }

        .create-fond {
          @include space-between;
          width: 100%;
          margin-top: 50px;
          @media only screen and (max-width: 500px) {
            display: block;
          }

          .create-inner {
            display: flex;
            align-items: flex-end;
            @media only screen and (max-width: 500px) {
              display: block;
              margin-bottom: 20px;
            }

            ::v-deep .button-container {
              margin-left: 10px;
              @media only screen and (max-width: 500px) {
                width: 100%;
              }

              .button-container-content {
                width: unset;
                padding: 0 20px;

              }
            }

          }

          .item {
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 500px) {
              width: 100% !important;
            }

            &:first-child {
              width: 60%;
              @media only screen and (max-width: 767px) {
                width: 50%;
              }
              @media only screen and (max-width: 500px) {
                margin-bottom: 20px;
              }
            }
          }

          .description {
            text-align: left;
            margin-bottom: 5px;
            margin-top: 0;
          }

          .input {
            width: 100%;
            background: transparent;
            border: 1px solid rgba(250, 250, 250, .5);
            border-radius: 10px;
            padding: 15px;
            color: white;
          }

          ::v-deep .button-container {
            margin-top: 0;
            align-self: flex-end;

            &.disable {
              .button-container-content {
                border: 1px solid rgba(250, 250, 250, .5);
                background: transparent;

                .button-title {
                  color: white;
                }
              }
            }

            .button-container-content {
              border-radius: 10px;
              height: 51px;
              border: 1px solid $base-color;

              @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                width: 142px;
              }
              @media only screen and (max-width: 500px) {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      width: 38%;
      @media only screen and (max-width: 1366px) {
        flex-direction: unset;
        justify-content: space-between;
        width: 100%;
        margin-top: 25px;
      }
      @media only screen and (max-width: 767px) {
        display: block;
      }

      .chart-box {
        border-radius: 15px;
        width: 100%;
        height: 350px;
        background: rgba(0, 0, 0, .3);
        @media only screen and (max-width: 1366px) {
          width: 49%;
        }
        @media only screen and (max-width: 1650px) and (min-width: 1366px) {
          height: 280px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          height: 250px;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-top: 20px;
          @media only screen and (max-width: 1366px) {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>