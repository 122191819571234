<template>
  <div id="sign_in" class="sign-up registration-basic">
    <Header/>
    <div class="content">
      <div class="header-container">
        <h2 class="header">sign in</h2>
        <div class="blur"></div>
      </div>
      <div class="form">
        <form-input v-for="item in inputs"
                    :name="item.name"
                    :key="item.name"
                    @onkeyEvent="inputKeyEvent"
                    v-model="item.value"
                    :title="item.title"
                    :type="item.type"/>
      </div>
      <!--      <span class="password-forgot">Forgot your password?</span>-->
      <span class="error-text">{{ error }}</span>
      <Button :disable="!submitAllow"
              :event="signIn"
              :showModal="false"
              title="Sign In"/>
<!--      <div class="bottom">-->
<!--        <p class="sign-in">Don't have an account?-->
<!--          <router-link to="/sign-up">Sign Up</router-link>-->
<!--        </p>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>
import FormInput from "@/components/FormInput";
import Button from "@/components/Button";
import Header from "@/components/Header";
import {mapActions} from "vuex";

export default {
  data() {
    return {
      inputs: [
        {
          type: "email",
          name: "email",
          title: "Email",
          value: '',
          error: false
        },
        {
          type: "password",
          name: "password",
          title: "Password",
          value: '',
          error: false
        },
      ],
      submitAllow: false,
      error: '',
    }
  },
  components: {
    FormInput,
    Button,
    Header
  },
  methods: {
    ...mapActions({logIn: 'auth/signIn'}),
    inputKeyEvent() {
      if (this.inputs[0].value !== '' && this.inputs[1].value !== '') {
        this.submitAllow = true
      }
    },
    signIn() {
      let username = this.inputs[0].value?.replace(/ /g, '')
      let password = this.inputs[1].value?.replace(/ /g, '')
      this.submitAllow = false
      try {
        return this.logIn({username, password}).then(() => {
          this.signIpSuccess()
        }).catch(e => {
          if (e.code === 'UserNotConfirmedException') {
            this.$router.push('/welcome')
            return
          }
          this.signInError(e)
        });
      } catch (error) {
        console.log('error signing in', error);
      }
    },
    signInError(error) {
      this.error = error.message
      this.inputs.forEach(item => item.value = '')
    },
    signIpSuccess() {
      this.$router.push({path: '/verification'})
    },
    enterEvent() {
      document.querySelector('#sign_in').addEventListener("keyup", (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          if (this.submitAllow) {
            document.querySelector('.button-container .button-container-content').click()
          }
        }
      });
    }
  },
  created() {
    this.$nextTick(() => {
      this.enterEvent()
    })
  }
}
</script>

<style lang="scss" scoped>
.sign-up {
  @include flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: conic-gradient(from 137.49deg at 48.7% 55.16%, #02000E 0deg, #090D23 189.38deg, #2C4C8A 326.25deg, #02000E 360deg);

  .content {
    margin-top: 80px;
    width: 699px;
    background: rgba(255, 255, 255, 0.06);
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 76px 72px 42px;
    @include flex;
    flex-direction: column;
    @media (max-width: 1023px) and (min-width: 768px) {
      width: 550px;
      margin-top: 35px;
      padding: 40px 52px 40px !important;
    }
    @media (max-width: 767px) {
      width: 90%;
      padding: 30px 20px !important;
    }
    @media (max-height: 860px) and (min-height: 760px) {
      margin-top: 50px;
      padding: 56px 72px 32px;
    }
    @media (max-height: 759px) {
      margin-top: 35px;
      padding: 40px 72px 40px;
    }

    .form {
      width: 100%;

      .form-input {
        flex: calc(50% - 20px);
        margin-top: 30px;

      }
    }
  }
}
</style>
