<template>
  <div class="step-2 id-verification">
    <div class="step-header"> identity verification</div>
    <div class="list">
      <div :class="{error:imageError,uploaded:imageUploaded}" class="upload-inner">
        <div class="upload-container">
          <input @input="upload" type="file" class="input">
          <span class="title">Upload ID’s front <br/>page </span>
          <div class="image-container">
            <img src="../assets/images/camera.png" class="image" alt="">
            <img src="../assets/images/done.png" class="image" alt="">
          </div>
        </div>
        <span class="error-text">something went wrong,try again!</span>
      </div>
    </div>
    <!--    <div class="upload-result">-->
    <!--      <img src="" id="uploadedImage" alt="">-->
    <!--    </div>-->
    <div class="about-container">
      <img src="../assets/images/about.png" class="image" alt="">
      <span class="title">This information is used for  identity verification only, and will be secure</span>
    </div>
    <Button :event="submitForm"
            :showModal="false"
            :errorEvent="errorEvent"
            :successEvent="formSuccess"
            :disable="!finishVerification" title="Next"/>
  </div>
</template>

<script>
import Button from "@/components/Button";
import {mapActions} from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    stepInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      imageError: false,
      imageUploaded: false,
      file: '',
    }
  },
  components: {
    Button,
  },
  computed: {
    finishVerification() {
      return this.imageUploaded
    }
  },
  methods: {
    ...mapActions({setActiveStepId: 'steps/setActiveStepId'}),
    upload(ev) {
      this.file = ev.target.files[0];
      if (this.file && this.file['type'].split('/')[0] === 'image') {
        let picture = new FileReader();
        picture.readAsDataURL(this.file);
        picture.addEventListener('load', () => {
          this.imageUploaded = true
          this.imageError = false
        });
      } else {
        this.imageUploaded = false
        this.imageError = true
      }
    },
    submitForm() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("type", this.type);
      return this.$axios.post(`api/v1/kyc/${this.stepInfo.id}/image`, formData)
    },
    formSuccess(data) {
      this.$emit('uploaded', {data, type: this.type})
    },
    errorEvent() {
      this.imageUploaded = false
      this.imageError = true
    }
  }
}
</script>

<style lang="scss" scoped>
.step-2 {
  .step-header {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: center;
  }

  .list {
    @include center-children;
    margin-top: 33px;

    .upload-inner {
      position: relative;

      &:first-child {
        margin-right: 20px;
      }

      &.error {
        .upload-container {
          border: 1px solid #EC0E00 !important;
        }

        .error-text {
          opacity: 1 !important;
        }
      }

      &.uploaded {
        .upload-container {
          background: #3FCA87;
          border: 1px solid transparent;

          img {
            &:nth-child(2) {
              opacity: 1 !important;
              transition: all .35s .3s ease-out;
            }

            &:nth-child(1) {
              opacity: 0 !important;
              transition: all .3s ease-out !important;
            }
          }
        }
      }
    }

    .error-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      text-transform: capitalize;
      color: #ED2E2E;
      opacity: 0;
      transition: all .4s ease-out;
      margin-top: 7px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
    }

    .upload-container {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 226px;
      height: 119px;
      background: #080B20;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 16px;
      padding-top: 15px;
      transition: all .4s ease-out;


      .title {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
      }

      .input {
        position: absolute;
        opacity: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .image-container {
        position: relative;
        margin-top: 17px;
        width: 30px;

        img {
          width: 100%;
          position: absolute;
          transition: all .4s ease-out;

          &:nth-child(2) {
            opacity: 0;
          }
        }
      }

    }
  }

  .upload-result {
    @include flex;
    margin: 64px auto 0;
    width: fit-content;

    img {
      width: 65.53px;
      height: 40.96px;
      border-radius: 10px;
      object-fit: cover;
      background: #E8E8E8;
      transition: all .4s ease-out;

      &:first-child {
        margin-right: 24px;
      }
    }

    img[src=""] {
      opacity: 0;
    }
  }

  .about-container {
    @include center-children;
    margin-top: 44px;

    .title {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      color: #FFFFFF;
      margin-left: 15px;
    }
  }

  .button-container {
    width: fit-content;
    margin: 40px auto 0;
  }
}
</style>