// let domainDev = process.env.VUE_APP_AWS_EXPORT_DOMAIN_DEV;
// let pollsIdDev = process.env.VUE_APP_AWS_USER_POOLS_ID_DEV;
// let clientIdDev = process.env.VUE_APP_AWS_USER_CLIENT_ID_DEV;
let domain = process.env.VUE_APP_AWS_EXPORT_DOMAIN;
let clientId = process.env.VUE_APP_AWS_USER_CLIENT_ID;
let pollsId = process.env.VUE_APP_AWS_USER_POOLS_ID;

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": pollsId,
    "aws_user_pools_web_client_id": clientId,
    "oauth": {
        "domain": domain,
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:4200/",
        "redirectSignOut": "http://localhost:4200/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
