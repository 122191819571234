<template>
  <div :class="{error}" class="form-input">
    <label class="title" for="name">{{ title }}</label>
    <input :type="type"
           v-bind:value="value"
           v-on:input="$emit('input', $event.target.value)"
           @keyup="onkeyup"
           :placeholder="title"
           pattern=".+@globex\.com"
           :id="name" class="input">
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
    }
  },
  methods: {
    onkeyup(event) {
      this.$emit('onkeyEvent', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-input {
  &.error {
    .input {
      border: 0.5px solid red;
    }
  }

  .input {
    background: rgba(7, 9, 29, 0.8);
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 58px;
    margin-top: 17px;
    height: 60px;
    width: 100%;
    padding-left: 33px;
    font-weight: 300;
    font-size: 16px;
    color: white;
    transition: all .4s ease;
    @media (max-height: 759px) {
      height: 55px;
    }
    @media (max-width: 1023px) {
      padding-left: 15px;
    }
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    display: block;
    font-family: 'Rubik', 'Roboto', sans-serif;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
}
</style>