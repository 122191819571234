<template>
  <div class="settings-view">
    <div class="settings-content">
      <div class="tab-container">
        <template>
          <v-tabs v-model="tab" @change="inputKeyEvent">
            <v-tab
                v-for="item in items"
                :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <form-input v-for="(item,index) in inputs"
                        :name="item.name"
                        :key="item.name"
                        :title="item.title"
                        @onkeyEvent="inputKeyEvent(index)"
                        :error="item.error"
                        v-model="item.value"
                        :type="item.type"/>
          </v-tab-item>
          <v-tab-item>
            <form-input name="wallet"
                        title="Withdraw wallet"
                        @onkeyEvent="inputKeyEvent()"
                        v-model="withdrawAddress"
                        type="text"/>
          </v-tab-item>
        </v-tabs-items>
        <Button
            :disable="!submitAllow"
            :event="updateSettings"
            :successEvent="successEvent"
            :errorEvent="errorEvent"
            :title="$tc('update')"/>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "@/components/FormInput";
import Button from "../Button";
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      tab: null,
      submitAllow: false,
      withdrawAddress: ''
    }
  },
  components: {
    FormInput,
    Button
  },
  computed: {
    ...mapGetters({
      userSettings: 'userSettings/getUserSettings'
    }),
    inputs() {
      return [
        {
          type: "password",
          name: "old_password",
          title: this.$tc('oldPassword'),
          value: '',
          error: false
        },
        {
          type: "password",
          name: "password",
          title: this.$tc('password'),
          value: '',
          error: false
        },
        {
          type: "password",
          name: "confirm_password",
          title: this.$tc('confirmPassword'),
          value: '',
          error: false
        },
      ]
    },
    items() {
      return window.innerWidth < 768 ? [this.$tc('password'), this.$tc('address')] : [this.$tc('changePassword'), this.$tc('withdrawAddress'),]
    },
  },
  methods: {
    ...mapActions({
      changePassword: 'auth/changePassword',
      setModalInfo: 'modal/setModalInfo',
      getUserSettings: 'userSettings/getUserSettings',
      setUserSettings: 'userSettings/setUserSettings',
      UpdateUserSettings: 'userSettings/UpdateUserSettings'
    }),
    checkPasswords() {
      let password = this.inputs[1]
      let rePassword = this.inputs[2]
      let passwordValue = document.getElementById(password.name)?.value
      let rePasswordValue = document.getElementById(rePassword.name)?.value

      if (passwordValue !== '' && passwordValue !== rePasswordValue) {
        password.error = true
        rePassword.error = true
        return false
      } else {
        password.error = false
        rePassword.error = false
        this.submitAllow = false
        return true
      }

    },
    inputKeyEvent(index) {
      if (this.tab === 1 && this.withdrawAddress && this.withdrawAddress !== '') {
        this.submitAllow = true
        return;
      }
      if (index === 1) return

      if (this.checkPasswords() && this.inputs[0].value !== '' && this.inputs[1].value !== '' && this.inputs[2].value !== '') {
        this.submitAllow = true
      }
    },
    updateSettings() {
      if (this.tab === 1) {
        return this.UpdateUserSettings({withdrawAddress: this.withdrawAddress})
      }

      return this.changePassword({
        oldPassword: this.inputs[0]?.value,
        newPassword: this.inputs[1]?.value
      })
    },
    successEvent() {
      if (this.tab === 0) this.inputs?.forEach(item => item.value = '')
      this.setModalInfo({
        title: 'Success',
        description: this.tab === 0 ? 'Password updated successfully' : 'Withdraw address updated successfully',
        type: true
      })
    },
    errorEvent(error) {
      this.setModalInfo({
        title: error?.error,
        description: error?.message,
        type: false
      })
    },
    setUserInfo() {
      this.getUserSettings()?.then(() => {
        this.withdrawAddress = this.userSettings?.withdrawAddress
      }).catch(error => {
        if (error.status === 404) {
          this.setUserSettings({withdrawAddress: ''})
        }
      })

    }
  },
  created() {
    this.setUserInfo()
  }
}
</script>

<style lang="scss" scoped>
.settings-view {
  width: 100%;
  padding: 22px 27px;
  height: 100%;
  @media only screen and (max-width: 1440px) {
    padding: 22px 19px;

  }

  .settings-content {
    height: 100%;

    .tab-container {
      display: flex;
      flex-direction: column;

      ::v-deep .v-tabs {
        .v-slide-group.v-tabs-bar {
          background: transparent;

          .v-slide-group__prev--disabled {
            display: none;
          }

          .v-tab {
            color: white;
            font-family: "Rubik";
            font-weight: 500;
            font-size: 16px;

            &.v-tab--active {
              background: #42E8E0;
              color: #0F0E15;
            }
          }

        }

      }

      ::v-deep .v-tabs-items {
        background: transparent;
        padding: 35px 20px;
        background: linear-gradient(180deg, rgba(26, 29, 38, 0.5) 66.89%, rgba(17, 17, 17, 0) 128.34%);
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
        border-radius: 35px;
        @media only screen and (max-width: 767px) {
          background: transparent;
          padding: 20px 0 0;
          border-radius: 0;
        }

        .form-input {
          margin-top: 35px;
          max-width: 40%;

          @media only screen and (max-width: 1440px) and (min-width: 1024px) {
            max-width: 60%;
          }
          @media only screen and (max-width: 1023px) {
            max-width: 100%;
          }
          @media only screen and (max-width: 767px) {
            margin-top: 25px;
          }

          &:first-child {
            margin-top: 0;
          }

          input {
            background: #1e1e1e;
          }
        }
      }

      ::v-deep .button-container {
        align-self: flex-end;

        .button-container-content {
          margin-top: 0 !important;
        }
      }
    }


  }
}
</style>