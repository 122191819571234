<template>
  <div class="coin-swiper">
    <div class="coin-swiper-inner">
      <div v-for="(item,index) in coins" :key="index" class="coin-item box">
        <div class="item-header">
          <div class="about">
            <img :src="item.img" class="image" alt="">
            <span class="title">{{ item.title }}</span>
          </div>
          <span class="description">{{ item.code }}<span>$</span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";

export default {
  data() {
    return {
      coins: []
    }
  },
  methods: {
    initSwiper() {
      let boxWidth = document.querySelector('.box')?.getBoundingClientRect()?.width;
      let containerWidth = boxWidth * this.coins.length
      document.querySelector('.coin-swiper-inner').style.left = -boxWidth - 20 + 'px'
      let area = containerWidth + this.coins.length * 20

      gsap.set(".box", {
        x: (i) => i * boxWidth + i * 20
      });

      gsap.to(".box", {
        duration: 15,
        ease: "none",
        x: `+=${area}`,
        modifiers: {
          x: gsap.utils.unitize(x => parseFloat(x) % area) //force x value to be between 0 and 500 using modulus
        },
        repeat: -1
      });

    },
    getPrices() {
      this.$axios.get('api/v1/price?discardFee=true').then(({data}) => {
        this.coins = data?.map(item => ({
          title: item.symbol,
          code: Math.round(item.price * 100) / 100,
          img: require(`@/assets/images/${item.symbol?.toLowerCase()}.png`)
        }));
        this.$nextTick(() => {
          this.initSwiper()
        })
      })
    }
  },
  created() {
    this.getPrices()

  }
};
</script>

<style lang="scss" scoped>
.coin-swiper {
  width: 100%;
  height: 76px;
  overflow: hidden;
  @media only screen and (max-width: 1023px) {
    margin-top: 20px;
  }

  .coin-swiper-inner {
    position: relative;
    height: 100%;
  }

  .coin-item {
    position: absolute;
    border-radius: 15px;
    padding: 20px 25px;
    width: calc(25% - 20px);
    background: rgba(0, 0, 0, .3);
    @media only screen and (max-width: 1366px) and (min-width: 1024px) {
      width: calc(35% - 15px);
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      width: calc(45% - 15px);
    }
    @media only screen and (max-width: 767px)  {
      width: calc(100% - 15px);
    }

    .item-header {
      @include space-between;

      .description {
        @include flex;
        font-size: 16px;
        color: white;
      }

      .about {
        @include flex;
        color: white;

        img {
          width: 30px;
        }

        .title {
          font-size: 22px;
          color: white;
          margin-left: 10px;
        }

      }

    }
  }
}
</style>