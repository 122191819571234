<template>
  <div class="hedge-fund">
    <div class="hedge-main-content">
      <div class="left">
        <div class="wallet-container">
          <div class="header-list">
            <div class="item">
              <div class="up">
                <img src="../../assets/images/tvl.png" alt="">
                <span class="title">Total Value Locked</span>
              </div>
              <span class="desc">{{ overallStats.totalBtcValueLocked }}</span>
            </div>
            <div class="item">
              <div class="up">
                <img src="../../assets/images/money-bag.png" alt="">
                <span class="title">Unrealized PNL</span>
              </div>
              <span class="desc"><span :class="(overallStats.unrealizedPL>=0?'active':'passive')">{{
                  overallStats.unrealizedPL
                }}%</span></span>
            </div>
            <div class="item">
              <div class="up">
                <img src="../../assets/images/amount.png" alt="">
                <span class="title">Your profit</span>
              </div>
              <span class="desc">{{ userProfit }} BTC</span>
            </div>
          </div>
          <div class="fond-amount">
            <div class="up">
              <img src="../../assets/images/btc.png" class="image" alt="">
              <span class="amount">{{ totalBalance }} <span>BTC</span></span>
            </div>
            <div class="tooltip-container">
              <div v-for="(item,index) in fundBalance" :key="index" class="item">
                <img :src="item.icon" alt="">
                <span class="title">{{ item.code }}:</span>
                <span class="title">{{ item.value }}</span>
              </div>
              <div class="down-triangle"></div>
            </div>
            <span class="description">{{ $tc('yourBalance') }}</span>
          </div>
          <!--          <div class="create-fond">-->
          <!--            <div class="item">-->
          <!--              <div class="item-input-inner">-->
          <!--                <span class="description">{{ $tc('writeInvestment') }}</span>-->
          <!--                <input @keyup="restrictValue" v-model="activeCurrencyValue" type="number" class="input">-->
          <!--              </div>-->
          <!--              <div  v-if="filteredTokens.length" class="crypto-container">-->
          <!--                <v-select :clearable="false"-->
          <!--                          :searchable="false"-->
          <!--                          @input="selectToken"-->
          <!--                          v-model="currency"-->
          <!--                          :options="filteredTokens">-->
          <!--                  <template v-slot:option="option">-->
          <!--                    <img :src="option.icon" alt="">-->
          <!--                    <span class="title">{{ option.label }}</span>-->
          <!--                  </template>-->
          <!--                  <template #selected-option="{ label, icon }">-->
          <!--                    <img :src="icon" alt="">-->
          <!--                    <span class="title">{{ label }}</span>-->
          <!--                  </template>-->
          <!--                </v-select>-->
          <!--              </div>-->
          <!--              <Button :disable="isCurrency"-->
          <!--                      :event="createNewInvestment"-->
          <!--                      :successEvent="investmentCreateResponseEvent"-->
          <!--                      :errorEvent="investmentCreateResponseEvent"-->
          <!--                      title="Deposit"/>-->
          <!--            </div>-->
          <!--            <div class="item">-->
          <!--              <Button :disable="disableWithdrawButton"-->
          <!--                      :event="withdrawWallet"-->
          <!--                      :successEvent="investmentCreateResponseEvent"-->
          <!--                      :errorEvent="investmentCreateResponseEvent"-->
          <!--                      title="Withdraw"/>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <fund-table :is-crypto="true"/>
      </div>
    </div>
  </div>
</template>

<script>
// import Button from "../Button";
import {mapActions, mapGetters} from "vuex";
import FundTable from "./FundTable";
// import vSelect from "vue-select";

export default {
  data() {
    return {
      chartInfo: null,
      currency: 0,
      tokens: [],
      activeCurrencyValue: 0,
    }
  },
  components: {
    // Button,
    FundTable,
    // vSelect
  },
  computed: {
    ...mapGetters({
      distribution: 'funds/getDistribution',
      overallStats: 'funds/getOverallStats',
      totalInvestment: 'funds/getTotalInvestment',
      fundRewards: 'funds/getFundRewards',
      balance: 'wallet/getBalance',
      funds: 'funds/getFunds'
    }),
    isCurrency() {
      return this.activeCurrencyValue <= 0 || !this.currency?.amount > 0
    },
    filteredTokens() {
      return this.tokens?.filter(tok => this.balance?.balance?.[tok.label])?.map(item => ({
        ...item,
        amount: this.balance?.balance?.[item.label]
      }))
    },
    totalBalance() {
      return this.balance.fundBalance?.BTC
    },
    fundBalance() {
      let balance = this.balance?.fundBalance

      if (!balance) return []

      const filterBalance = Object.fromEntries(
          Object.entries(balance).filter(([key]) => key !== 'DFORT'))

      return Object.entries(filterBalance)?.map(item => ({
        code: item[0],
        value: item[1],
        icon: require(`@/assets/images/${item[0]?.toLowerCase()}.png`)
      }))
    },
    userProfit() {
      return this.fundRewards.filter(item => item.status === 'PENDING')?.reduce((accumulator, object) => {
        return accumulator + object.reward;
      }, 0);
    }
  },
  methods: {
    ...mapActions({
      createFundInvestment: 'funds/createFundInvestment',
      getDistribution: 'funds/getFundDistribution',
      getOverallStats: 'funds/getOverallStats',
      getTotalInvestment: 'funds/getTotalInvestment',
      getBalance: 'wallet/getBalance',
      getFunds: 'funds/getFunds',
    }),
    floorBy(number) {
      return Math.floor(number * 10000) / 10000
    },
    createNewInvestment() {
      return this.createFundInvestment({
        amount: this.activeCurrencyValue,
        balanceType: this.currency?.label
      })
    },
    async investmentCreateResponseEvent() {
      await this.getBalance()
      this.currency = this.filteredTokens?.find(item => item.label = this.currency.label)
    },
    selectToken(e) {
      this.activeCurrencyValue = e?.amount
    },
    getPrices() {
      this.$axios.get('api/v1/wallet/deposit/token-type').then(({data}) => {
        this.tokens = data?.map(item => ({
          label: item.symbol,
          code: item.price,
          icon: require(`@/assets/images/${item.symbol?.toLowerCase()}.png`)
        }));
        this.currency = this.filteredTokens[0]
        this.selectToken(this.currency)
      })

    },
    restrictValue() {
      if (this.activeCurrencyValue > this.currency.amount) this.activeCurrencyValue = this.currency.amount
    },
    withdrawWallet() {
      this.fundRewards.filter(item => item.status === 'PENDING')?.forEach(item => {
        return this.$axios.post(`/api/v1/fund/rewards/${item.id}/withdraw`, {}, {
          openModal: true,
          title: 'withdraw request successfully created'
        }).then(() => {
          this.getTotalInvestment()
        })
      })
    },
    disableWithdrawButton() {
      return this.userProfit <= 0
    },
  },
  async created() {
    await this.getFunds()
    await this.getBalance()
    this.getTotalInvestment()
    this.getPrices()
    let type = this.funds?.find(item => item.type === 'CRYPTO')

    this.getOverallStats(type?.id)
  }
}
</script>

<style lang="scss" scoped>
.hedge-fund {
  width: 100%;
  height: 100%;
  background: #1e1e1e;
  padding: 0 27px;
  @media only screen and (max-width: 1440px) and (min-width: 1024px) {
    padding: 0 19px;
  }
  @media only screen and (max-width: 1023px) {
    background: transparent;
    padding: 0;
  }

  .hedge-main-content {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @media only screen and (max-width: 1366px) {
      display: block;
    }
    @media only screen and (max-width: 1023px) {
      padding: 19px;
      margin-top: 10px;
    }

    .left {
      padding: 30px 0;
      border-radius: 15px;
      width: 100%;
      background: rgba(0, 0, 0, .3);

      @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        padding: 15px;
      }
      @media only screen and (max-width: 1366px) {
        width: 100%;
      }
      @media only screen and (max-width: 1023px) {
        padding: 0;
        border: unset;
      }

      .wallet-container {
        @include center-children;
        flex-direction: column;
        padding: 25px 15%;
        width: 100%;
        border-bottom: 1px solid rgba(250, 250, 250, .3);
        @media only screen and (max-width: 1650px) {
          padding: 25px 100px;
        }
        @media only screen and (max-width: 767px) {
          padding: 15px;
        }

        .header-list {
          display: flex;
          justify-content: space-between;
          width: 100%;
          @media only screen and (max-width: 500px) {
            display: block;
          }

          .item {
            color: #FFFFFF;
            @media only screen and (max-width: 500px) {
              margin-bottom: 15px;
            }

            .up {
              @include flex;

              img {
                width: 18px;
              }

              .title {
                margin-left: 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                color: rgba(250, 250, 250, .8);
              }

            }

            .desc {
              @include flex;
              font-weight: 500;
              font-size: 20px;
              margin-top: 10px;

              span {
                font-size: 20px;

                &.active {
                  color: green;
                }

                &.passive {
                  color: red;
                }
              }
            }
          }
        }

        .fond-amount {
          margin-top: 50px;
          position: relative;
          cursor: pointer;

          &:hover {
            .tooltip-container {
              opacity: 1;
              pointer-events: auto;
            }
          }

          .up {
            @include flex;

            .amount {
              display: flex;
              font-weight: 500;
              font-size: 26px;
              color: #FFFFFF;
              margin-left: 15px;

              span {
                color: $base-color;
                margin-left: 5px;
              }
            }
          }

          .tooltip-container {
            position: absolute;
            background: white;
            border-radius: 10px;
            padding: 20px;
            min-width: 150px;
            top: 0;
            left: 50%;
            transform: translate(-50%, calc(-100% - 20px));
            opacity: 0;
            transition: all .4s ease-out;
            pointer-events: none;

            .item {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }

              img {
                width: 20px;
                margin-right: 5px;
              }

              .title {
                color: #1E1E1E;
                font-weight: 500;
                display: block;


                &:last-child {
                  margin-left: 5px;
                }
              }
            }

            .down-triangle {
              position: absolute;
              bottom: -14px;
              left: 50%;
              transform: translateX(-50%);
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 15px solid white;
            }
          }
        }

        .description {
          color: rgba(250, 250, 250, .7);
          text-align: center;
          margin-top: 5px;
          font-size: 14px;
        }

        .create-fond {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          width: 100%;
          margin-top: 50px;
          @media only screen and (max-width: 1440px) {
            display: block;
          }
          @media only screen and (max-width: 500px) {
            display: block;
          }

          .item {
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 500px) {
              width: 100% !important;
            }

            &:first-child {
              width: 60%;
              flex-direction: row;
              align-items: flex-end;
              justify-content: space-between;
              @media only screen and (max-width: 1440px) and (min-width: 768px) {
                width: 100%;
                margin-bottom: 15px;
              }
              @media only screen and (max-width: 767px) {
                width: 100%;
                flex-direction: column;
              }
              @media only screen and (max-width: 500px) {
                margin-bottom: 20px;
              }
            }

            ::v-deep .v-select {
              width: 100%;
              height: 100%;
              border: 1px solid rgba(250, 250, 250, 0.5);
              border-radius: 10px;

              .vs__dropdown-toggle {
                width: 100%;
                height: 100%;
                border: unset;
                padding: 0;

                .vs__selected-options {
                  flex-grow: unset;
                  flex-wrap: unset;
                  padding: 0;
                  flex-basis: unset;
                  width: 100%;
                  @include center-children;
                }

                .vs__selected {
                  @include center-children;
                  width: 100%;
                  height: 100%;
                  border: unset;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  color: #FFFFFF;
                  font-family: "Rubik";
                  padding: 0;
                  margin: 0;
                }

                input {
                  display: none;
                }
              }

              .vs__dropdown-menu {
                background: transparent;
                border: 1px solid #1e1e1e;
                padding: 0;
                border-top: unset;

                li {
                  @include flex;
                  padding: 5px 10px 5px 10px;
                  background: #171717;

                  &:first-child {
                    margin-top: 0;
                  }

                  &:hover, &.vs__dropdown-option--selected, &.vs__dropdown-option--highlight {
                    background: $base-color;

                    .title {
                      color: #0F0E15;
                    }
                  }

                  .title {
                    color: white;
                    margin-left: 10px;
                  }
                }
              }

              .vs__actions {
                display: none;
              }
            }

            .crypto-container {
              @include center-children;
              height: 51px;
              min-width: 20%;
              max-width: 20%;
              border-radius: 10px;
              margin-right: 15px;

              @media only screen and (max-width: 767px) {
                min-width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
              }

              .dfort-container {
                @include center-children;
                border: 2px solid #1280A0;
                border-radius: 50%;
                height: 35px;
                width: 35px;

                img {
                  width: 11px;
                }
              }

              img {
                height: 30px;
                width: 30px;
                object-fit: contain;
              }

              .title {
                color: white;
                font-size: 18px;
                margin-left: 10px;
              }
            }

            .item-input-inner {
              width: 60%;
              margin-right: 15px;
              @media only screen and (max-width: 767px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
              }
            }
          }

          .description {
            text-align: left;
            margin-bottom: 5px;
            margin-top: 0;
          }

          .input {
            width: 100%;
            background: transparent;
            border: 1px solid rgba(250, 250, 250, .5);
            border-radius: 10px;
            padding: 15px;
            color: white;
          }

          ::v-deep .button-container {
            margin-top: 0;
            align-self: flex-end;
            @media only screen and (max-width: 767px) {
              min-width: 100%;
              margin-bottom: 20px
            }

            &.disable {
              .button-container-content {
                border: 1px solid rgba(250, 250, 250, .5);
                background: transparent;

                .button-title {
                  color: white;
                }
              }
            }

            .button-container-content {
              border-radius: 10px;
              height: 51px;
              border: 1px solid $base-color;

              @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                width: 142px;
              }
              @media only screen and (max-width: 767px) {
                min-width: 100%;
              }
            }
          }
        }
      }

      ::v-deep .fund-table {
        padding: 30px 15%;

        .v-data-table th, tr {
          background: transparent;
        }
      }
    }

  }
}
</style>