import {Auth} from "aws-amplify";

export default {
    namespaced: true, state: () => ({
        user: null
    }), actions: {
         signIn({commit}, user) {
            return Auth.signIn(user.username, user.password).then((user) => {
                commit('SET_USER', user)
            })
        }, updateUserTokens({commit}, session) {
            commit('UPDATE_USER_SESSION', session)
        }, changePassword({commit}, {oldPassword, newPassword}) {
            console.log(commit)
            return Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, oldPassword, newPassword);
                })
        },
    }, mutations: {
        SET_USER(state, user) {
            state.user = user
        }, UPDATE_USER_SESSION(state, session) {
            state.user.signInUserSession = session
        },
    }, getters: {
        getUser(state) {
            return state.user
        }
    }
}