<template>
  <div class="welcome-fotchain">
    <Header/>
    <div class="welcome-content">
      <p class="header">
        Welcome to <span>Fortchain</span> !<br/>
        please verify your email address!
      </p>
      <span class="about">
        Thank you usingFortchain. you’ve entered<br/>
        <span>{{ user }}</span> as the email
        address for your account. Please verify this email address by clicking the button below
      </span>
      <Button @click.native="changeRoute" :showModal="false" title="Sign in"/>
    </div>
    <img src="../assets/images/rocket.png" class="rocket-image" alt="">
    <img src="../assets/images/vector.png" class="vector-image" alt="">

  </div>
</template>

<script>
import Button from "@/components/Button";
import Header from "@/components/Header";

export default {
  props: {
    user: {
      type: String,
      default: ''
    }
  },
  components: {
    Button,
    Header
  },
  methods: {
    changeRoute() {
      this.$router.push({path: '/sign-in'}).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome-fotchain {
  @include flex;
  flex-direction: column;
  position: relative;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  min-height: 100vh;
  background: conic-gradient(from 137.49deg at 48.7% 55.16%, #02000E 0deg, #090D23 189.38deg, #2C4C8A 326.25deg, #02000E 360deg);
  overflow: hidden;

  .welcome-content {
    @include flex;
    margin-top: 150px;
    background: rgba(255, 255, 255, 0.06);
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 76px 288px;
    width: 1233px;
    position: relative;
    flex-direction: column;
    z-index: 1;
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      width: 1000px;
      padding: 76px 200px;
      margin-top: 80px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1023px) {
      width: 900px;
      padding: 50px 100px;
      margin-top: 80px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      width: 90%;
      padding: 30px 20px;
      margin-top: 45px;
    }
    @media only screen and (max-width: 767px) {
      width: 90%;
      padding: 30px 10px;
      margin-top: 45px;
    }

    .header {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 50px;
      text-align: center;
      text-transform: capitalize;
      color: white;
      @media only screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 30px;
      }

      span {
        color: $base-color;
        display: inline-block;
      }
    }

    .about {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #FFFFFF;
      margin-top: 40px;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        margin-top: 20px;
        br {
          display: none;
        }
      }

      span {
        color: #322a77;
        display: inline-block;
      }
    }

    .button-container {
      margin-top: 40px;
      @media only screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }

  .rocket-image {
    position: absolute;
    top: 200px;
    right: -40px;
    transform: rotate(2.79deg);
    @media only screen and (max-width: 1023px) {
      display: none;
    }
  }

  .vector-image {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: .2;
    width: 80%;
    @media only screen and (max-width: 1023px) {
      display: none;
    }
  }
}
</style>