import Vue from "vue";

let $vm = Vue.prototype

export default {
    namespaced: true,
    state: () => ({
        funds: [],
        investment: [],
        fundRewards: [],
        distribution: {},
        overallStats: 0,
        userTotalInvestment: null
    }),
    actions: {
        getFunds({commit},) {
            return $vm.$axios.get(`api/v1/fund/pool`).then(({data}) => {
                commit('SET_FUNDS', data)
            })
        },
        getInvestment({commit}, isCrypto = false) {
            return $vm.$axios.get(`api/v1/fund/investment?isCrypto=${isCrypto}`).then(({data}) => {
                commit('SET_INVESTMENTS', data)
            })
        },
        getFundRewards({commit}, isCrypto = false) {
            return $vm.$axios.get(`api/v1/fund/rewards?isCrypto=${isCrypto}`).then((e) => {
                commit('SET_FUND_REWARDS', e.data)
            })
        },
        getOverallStats({commit}, id) {
            return $vm.$axios.get(`api/v1/fund/pool/${id}/stats`).then(({data}) => {
                commit('SET_OVERALL_STATS', data)
            })
        },
        getTotalInvestment({commit}) {
            return $vm.$axios.get('api/v1/fund/investment/total/me').then(({data}) => {
                commit('SET_TOTAL_INVESTMENT', data)
            })
        },
        getFundDistribution({commit}) {
            return $vm.$axios.get('api/v1/fund/pool/distribution').then(({data}) => {
                commit('SET_FUND_DISTRIBUTION', data)
            })
        },
        createFundInvestment({commit}, {amount, balanceType = 'DFORT'}) {
            return $vm.$axios.post(`api/v1/fund/investment/request`, {
                amount,
                balanceType
            }, {
                openModal: true,
                title: 'Fund investment request successfully created'
            }).then(({data}) => {
                commit('SET_INVESTMENTS', data)
            })
        },
    },
    mutations: {
        SET_FUNDS(state, data) {
            state.funds = data.map(item => ({
                ...item,
                createdAt: $vm.moment(item.createdAt).format('DD/MM/YYYY'),
                startDate: $vm.moment(item.startDate).format('DD/MM/YYYY'),
                status: item.status,
                amount: item.balance,
            }))
        },
        SET_FUND_DISTRIBUTION(state, data) {
            let object = data?.sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.distribution
            if (!object) return
            state.distribution = Object?.keys(object)?.map((key) => ({
                value: object[key],
                name: key
            }))
        },
        SET_INVESTMENTS(state, data) {
            if (!(data instanceof Array)) {
                state.investment.push({
                    ...data,
                    createdAt: $vm.moment(data.createdAt).format('DD/MM/YYYY'),
                    startDate: $vm.moment(data.startDate).format('DD/MM/YYYY'),
                    status: data.status,
                    icon: require(`@/assets/images/${data.status?.toLowerCase()}.png`),
                    name: data.fundPool?.name
                })
                return
            }
            state.investment = data.map(item => ({
                ...item,
                createdAt: $vm.moment(item.createdAt).format('DD/MM/YYYY'),
                startDate: $vm.moment(data.startDate).format('DD/MM/YYYY'),
                status: item.status,
                icon: require(`@/assets/images/${item.status?.toLowerCase()}.png`),
                name: item.fundPool?.name
            }))
        },
        SET_FUND_REWARDS(state, data) {
            state.fundRewards = data.map(item => ({
                ...item,
                createdAt: $vm.moment(item.createdAt).format('DD/MM/YYYY'),
                status: item.status,
                icon: require(`@/assets/images/${item.status?.toLowerCase()}.png`)
            }))
        },
        SET_OVERALL_STATS(state, data) {
            state.overallStats = data
        },
        SET_TOTAL_INVESTMENT(state, data) {
            state.userTotalInvestment = data
        },
    },
    getters: {
        getOverallStats(state) {
            return state.overallStats;
        },
        getTotalInvestment(state) {
            return state.userTotalInvestment;
        },
        getFunds(state) {
            return state.funds
        },
        getFundRewards(state) {
            return state.fundRewards
        },
        getInvestment(state) {
            return state.investment
        },
        getDistribution(state) {
            return state.distribution
        },
    }
}