import axios from "axios";
import Vue from 'vue'
import router from '../router/index'
import store from '../store/index'
import {Auth} from 'aws-amplify';
// const devInstance = createInstance("http://192.168.0.102:5000/");
let url = process.env.VUE_APP_URL;

const devInstance = createInstance(url);

// const productionInstance = createInstance("http://localhost:3000"); // will change later
function setModalInfo(data) {
    store.commit('modal/SET_MODAL_INFO', data)
}

function signOut() {
    try {
        Auth.signOut().then(() => {
            router.push({path: 'sign-in'})
        });
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

async function refreshedToken() {
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            store.dispatch('auth/updateUserTokens', session);
        });
    } catch (e) {
        console.log('Unable to refresh Token', e);
        signOut()
    }
}

function createInstance(baseURL) {
    return axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
}

devInstance.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            if (response.config?.openModal) {
                setModalInfo({
                    title: 'Success',
                    description: response.config?.title,
                    type: true
                })
            }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        const originalConfig = error.config;
        if (error.response.status === 401) {
            originalConfig._retry = true
            return refreshedToken().then(() => {
                return devInstance(originalConfig);
            })
        }
        if (error.response.status) {
            if (error.response.config?.openModal) {
                setModalInfo({
                    title: error.response?.data?.error,
                    description: error.response?.data?.message,
                    type: false
                })
            }
            return Promise.reject(error.response);
        }
    }
);

devInstance.interceptors.request.use(config => {
        let token = store.getters["auth/getUser"]?.signInUserSession?.accessToken?.jwtToken;

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default {
    install() {
        Vue.prototype.$axios = devInstance
    }
};