<template>
  <div id="sign_up" class="sign-up registration-basic">
    <Header/>
    <div key="signup" class="sign-up-inner">
      <div class="content">
        <div class="header-container">
          <h2 class="header">sign up</h2>
          <div class="blur"></div>
        </div>
        <div class="form">
          <form-input v-for="(item,index) in inputs"
                      :name="item.name"
                      :key="item.name"
                      :title="item.title"
                      :error="item.error"
                      @onkeyEvent="inputKeyEvent(index)"
                      v-model="item.value"
                      :type="item.type"/>
        </div>
        <span class="error-text">{{ error }}</span>
        <Button :event="signUp"
                :showModal="false"
                :loading="loading"
                :disable="!submitAllow" title="Sign Up"/>
      </div>
      <p class="sign-in">Already Registered?
        <router-link to="/sign-in">Sign In</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import FormInput from "@/components/FormInput";
import Button from "@/components/Button";
import Header from "@/components/Header";
import {Auth} from 'aws-amplify';

export default {
  data() {
    return {
      inputs: [
        {
          type: "email",
          name: "email",
          title: "Email",
          value: '',
        },
        {
          type: "password",
          name: "password",
          title: "Password",
          value: '',
          error: false
        },
        {
          type: "password",
          name: "re_password",
          title: "Re-enter Password",
          value: "",
          error: false
        },
      ],
      submitAllow: false,
      user: null,
      loading: false,
      error: ''
    }
  },
  components: {
    FormInput,
    Button,
    Header
  },
  methods: {
    checkPasswords() {
      let password = this.inputs[1]
      let rePassword = this.inputs[2]
      let passwordValue = document.getElementById(password.name).value
      let rePasswordValue = document.getElementById(rePassword.name).value

      if (passwordValue !== '' && passwordValue !== rePasswordValue) {
        password.error = true
        rePassword.error = true
      } else {
        password.error = false
        rePassword.error = false
        this.submitAllow = false

        return true
      }

    },
    inputKeyEvent(index) {
      if (index === 1) return

      if (this.checkPasswords() && this.inputs[0].value !== '' && this.inputs[1].value !== '' && this.inputs[2].value !== '') {
        this.submitAllow = true
      }
    },
    signUp() {
      try {
        this.submitAllow = false

        return Auth.signUp({
          username: this.inputs[0].value?.replace(/ /g, ''),
          password: this.inputs[1].value?.replace(/ /g, ''),
        }).then((resp) => {
          this.$router.push('/welcome')
          this.user = resp.user.username
        }).catch(error => {
          this.signUpError(error)
        });
      } catch (error) {
        this.submitAllow = true
      }
    },
    signUpError(error) {
      this.error = error.message
      this.inputs.forEach(item => item.value = '')
    },
    enterEvent() {
      document.querySelector('#sign_up').addEventListener("keyup", (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          if (this.submitAllow) {
            document.querySelector('.button-container .button-container-content').click()
          }
        }
      });
    }
  },
  created() {
    this.$nextTick(() => {
      this.enterEvent()
    })
  }
}
</script>

<style lang="scss" scoped>
.sign-up {
  @include flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(109.04deg, #02000E 0.52%, #090D23 62.82%, #2C4C8A 90.85%);
  min-height: 100vh;

  .sign-up-inner {
    @include center-children;
    flex-direction: column;
  }

  .content {
    @include flex;
    margin-top: 80px;
    background: rgba(255, 255, 255, 0.06);
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 76px 72px 42px;
    width: 699px;
    flex-direction: column;
    @media (max-width: 1023px) and (min-width: 768px) {
      width: 550px;
      margin-top: 35px;
      padding: 40px 52px 40px !important;
    }
    @media (max-width: 767px) {
      width: 90%;
      padding: 30px 20px !important;
    }
    @media (max-height: 860px) and (min-height: 760px) {
      margin-top: 50px;
      padding: 56px 72px 32px;
    }
    @media (max-height: 759px) {
      margin-top: 35px;
      padding: 40px 72px 40px;
    }

    .form {
      @include flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .form-input {
        flex: calc(50% - 20px);
        margin-top: 30px;
        @media (max-width: 1023px) {
          flex: 100%;
        }

        &:nth-child(1) {
          flex: 100%;
          margin-right: 0 !important;
        }

        &:nth-child(2), &:nth-child(3) {
          margin-right: 20px;
          @media (max-width: 1023px) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .sign-in {
    margin-top: 40px;

    @media (max-width: 1023px) {
      margin-top: 25px;

    }
    @media (max-height: 860px) and (min-height: 760px) {
      margin-top: 30px;
    }
    @media (max-height: 759px) {
      margin-top: 25px;
    }
  }


  .list-enter-active {
    transition: all .4s .4s ease;
  }

  .list-leave-active {
    transition: all .4s ease;
  }

  .list-enter, .list-leave-to {
    opacity: 0;
  }
}
</style>
