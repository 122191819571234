<template>
  <div class="staking">
<!--    <div class="staking-header">-->
<!--      <div class="left">-->
<!--        <input type="number" placeholder="DF 10000" v-model="stakeValue" class="input">-->
<!--        <v-select :clearable="false" :searchable="false" v-model="stakeType" :options="stakeSelectOptions">-->
<!--          <template v-slot:option="option">-->
<!--            <img v-if="option.code===1" src="../../assets/images/stake.png" class="image" alt="">-->
<!--            <img v-else src="../../assets/images/radiation_clock.png" class="image" alt="">-->
<!--            <span class="title">{{ option.label }}</span>-->
<!--          </template>-->
<!--          <template #selected-option="{ label, code }">-->
<!--            <img v-if="code===1" src="../../assets/images/stake.png" class="image" alt="">-->
<!--            <img v-else src="../../assets/images/radiation_clock.png" class="image" alt="">-->
<!--            <span class="title">{{ label }}</span>-->
<!--          </template>-->
<!--        </v-select>-->
<!--      </div>-->
<!--      <div class="right">-->
<!--        <div class="value-container">-->
<!--          <div class="item">-->
<!--            <span class="month">apr</span>-->
<!--            <v-tooltip top>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <img v-bind="attrs"-->
<!--                     v-on="on"-->
<!--                     src="../../assets/images/question.png" alt="">-->
<!--              </template>-->
<!--              <span>apr is annual profit rate</span>-->
<!--            </v-tooltip>-->
<!--          </div>-->
<!--          <div class="item">-->
<!--            <span class="value">{{ stakeType.apyMin }}% - {{ stakeType.apyMax }}%</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <Button :disable="disableCreateStake"-->
<!--                :event="createNewStake"-->
<!--                :successEvent="stakeCreateResponseEvent"-->
<!--                :errorEvent="stakeCreateResponseEvent"-->
<!--                :title="$tc('stake')"/>-->
<!--      </div>-->
<!--    </div>-->
    <div class="staking-content-container">
      <staking-table/>
    </div>
  </div>
</template>

<script>
// import Button from "../Button";
// import vSelect from 'vue-select'
import StakingTable from "./StakingTable";
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      stakeValue: null,
      stakeType: {},
    }
  },
  components: {
    // Button,
    // vSelect,
    StakingTable
  },
  computed: {
    ...mapGetters({
      stakePackages: 'stake/getStakePackages'
    }),
    stakeSelectOptions() {
      return this.stakePackages?.map(item => ({
        label: item.name || 'stake',
        code: item.id,
        apyMin: item.apyMin,
        apyMax: item.apyMax,
      }))
    },
    disableCreateStake() {
      return this.stakeValue < 1
    }
  },
  methods: {
    ...mapActions({
      getStakePackages: 'stake/getStakePackages',
      createStake: 'stake/createStake',
      getStakes: 'stake/getStakes',
      setModalInfo: 'modal/setModalInfo'
    }),
    createNewStake() {
      return this.createStake({
        packageId: this.stakeType?.code,
        amount: this.stakeValue,
      })
    },
    stakeCreateResponseEvent() {
      this.stakeValue = 0
    }
  },
  created() {
    this.getStakePackages().then(() => {
      this.stakeType = this.stakeSelectOptions?.[0]
    })
    this.getStakes()
  }
}
</script>

<style lang="scss" scoped>
.staking {
  .staking-header {
    @include space-between;
    padding: 31px 18px;
    background: #272727;
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      display: block;
    }
    @media only screen and (max-width: 1023px) {
      padding: 19px;
    }
    @media only screen and (max-width: 767px) {
      display: block;
      background: transparent;
    }

    .left {
      @include flex;
      @media only screen and (max-width: 767px) {
        display: block;
      }

      .input {
        width: 240px;
        padding-left: 30px;
        background: #171717;
        border-radius: 70px;
        font-size: 20px;
        line-height: 30px;
        color: #FFFFFF;
        height: 60px;
        border: unset;
        @media only screen and (max-width: 1800px) and (min-width: 1024px) {
          width: 200px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          width: 200px;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
          background: #1E1E1E;
        }
      }

      ::v-deep .v-select {
        width: 287px;
        height: 60px;
        border: 1px solid #7D7D7D;
        border-radius: 70px;
        background: #171717;
        margin-left: 30px;
        @media only screen and (max-width: 1800px) and (min-width: 1366px) {
          width: 230px;
          margin-left: 20px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          width: 250px;
          margin-left: 20px;;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          width: 220px;
          margin-left: 20px;
        }
        @media only screen and (max-width: 767px) {
          margin-left: 0;
          width: 100%;
          margin-top: 15px;
          background: #1E1E1E;
        }

        .vs__dropdown-toggle {
          border: unset;
          height: 100%;
        }

        .vs__selected-options {
          padding-left: 20px;
        }

        input {
          font-size: 20px;
          color: #FFFFFF;
          padding: 0;
        }

        .vs__selected {
          position: relative !important;
          @include flex;
          font-size: 20px;
          color: #FFFFFF;
          padding: 0;
          @media only screen and (max-width: 1023px) and (min-width: 768px) {
            font-size: 16px;
          }

          .title {
            margin-left: 10px;
          }
        }

        .vs__open-indicator {
          right: 30px;
          position: absolute;

          path {
            fill: white;
          }
        }

        .vs__dropdown-menu {
          background: transparent;
          border: 0;

          li {
            @include flex;
            border-radius: 20px;
            margin-top: 5px;
            padding: 10px 20px;
            background: #171717;

            &:first-child {
              margin-top: 0;
            }

            &:hover, &.vs__dropdown-option--selected, &.vs__dropdown-option--highlight {
              background: $base-color;

              .title {
                color: #0F0E15;
              }
            }

            .title {
              color: white;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .right {
      @include flex;
      align-items: flex-end;
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        margin-top: 35px;
      }
      @media only screen and (max-width: 767px) {
        display: block;
        margin-top: 40px;
      }

      .value-container {
        .item {
          @include flex;

          &:last-child {
            margin-top: 10px;
          }

          .month {
            font-weight: normal;
            font-size: 16px;
            color: #FFFFFF;
          }

          .value {
            font-weight: 500;
            font-size: 20px;
            color: #FFFFFF;
            @media only screen and (max-width: 1800px) and (min-width: 1366px) {
              font-size: 18px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
              font-size: 16px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
              font-size: 18px;
            }
          }

          img {
            margin-left: 16px;
            cursor: pointer;
          }
        }
      }

      ::v-deep .button-container {
        margin-top: 0;
        margin-left: 50px;
        @media only screen and (max-width: 1800px) and (min-width: 1366px) {
          margin-left: 20px;
          width: 140px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          width: 160px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          margin-left: 20px;
        }
        @media only screen and (max-width: 767px) {
          @include center-children;
          width: 100%;
          margin-left: 0;
          margin-top: 40px;
          .button-title {
            font-size: 20px;
          }
        }
      }
    }
  }

  .staking-content-container {
    padding: 0 27px;
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
      padding: 0 20px;
    }
  }
}
</style>