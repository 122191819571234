import Vue from "vue";

let $vm = Vue.prototype

export default {
    namespaced: true,
    state: () => ({
        userSettings: null
    }),
    actions: {
        getUserSettings({commit}) {
            return $vm.$axios.get(`api/v1/user/settings`).then((resp) => {
                commit('SET_USER_SETTINGS', resp.data)
            })
        },
        setUserSettings({commit}, {withdrawAddress}) {
            return $vm.$axios.post(`api/v1/user/settings`, {withdrawAddress}).then((resp) => {
                commit('SET_USER_SETTINGS', resp.data)
            })
        },
        UpdateUserSettings({commit, state}, {withdrawAddress}) {
            return $vm.$axios.put(`api/v1/user/settings/${state.userSettings?.id}`, {withdrawAddress}).then((resp) => {
                commit('SET_USER_SETTINGS', resp.data)
            })
        },

    },
    mutations: {
        SET_USER_SETTINGS(state, userSettings) {
            state.userSettings = userSettings
        },
    },
    getters: {
        getUserSettings(state) {
            return state.userSettings
        }
    }
}