var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fund-table"},[_c('div',{staticClass:"transaction-header-container"},[_c('div',{staticClass:"tabs-list"},[_c('div',{staticClass:"item ",class:{active:_vm.activeTabIndex===0},on:{"click":function($event){_vm.activeTabIndex=0}}},[_vm._v(_vm._s(_vm.$tc('transaction')))]),_c('div',{staticClass:"item",class:{active:_vm.activeTabIndex===1},on:{"click":function($event){_vm.activeTabIndex=1}}},[_vm._v(_vm._s(_vm.$tc('rewards')))]),_c('div',{staticClass:"item",class:{active:_vm.activeTabIndex===2},on:{"click":function($event){_vm.activeTabIndex=2}}},[_vm._v(_vm._s(_vm.$tc('pools')))])])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.activeTabTransaction,"items-per-page":5,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(head,index){return _c('th',{key:index},[_c('div',{staticClass:"header-item"},[_c('img',{attrs:{"src":require(("@/assets/images/" + (head.iconName) + ".png")),"alt":""}}),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(head.text.toUpperCase()))])])])})}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-flex"},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.status))])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-flex"},[_c('span',[_vm._v(_vm._s(item.amount)+" "+_vm._s(item.balanceType))])])]}},{key:"item.reward",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-flex"},[_c('span',[_vm._v(_vm._s(item.reward)+" "+_vm._s(item.type))])])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-flex"},[_c('span',[_vm._v(_vm._s(item.balance)+" "+_vm._s(item.balanceType))])])]}},{key:"item.tvl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-flex"},[_c('span',[_vm._v(_vm._s(item.tvl)+" DFORT")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }