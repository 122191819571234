<template>
  <div :class="{disable,active:clicked}" class="button-container">
    <div @click="clickEvent" class="button-container-content">
      <span class="button-title">{{ title }}</span>
      <transition name="fade">
        <img v-if="loading" src="../assets/images/loading.png" class="loading" alt="">
      </transition>
    </div>
    <div @click.self="closeModal" class="confirmation-container">
      <div class="confirmation-content">
        <span class="title">Are you sure you want {{ title }} ?</span>
        <div class="confirmation-button-container">
          <div @click="confirmClick(false)" class="confirmation-button no">no</div>
          <div @click="confirmClick(true)" class="confirmation-button yes">Yes</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
    event: {
      type: Function,
      default: () => {
      }
    },
    successEvent: {
      type: Function,
      default: () => {
      }
    },
    errorEvent: {
      type: Function,
      default: () => {
      }
    },
    showModal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      clicked: false,
      loading: false,
    }
  },
  methods: {
    clickEvent() {
      if (!this.showModal) {
        this.acceptEvent()
        return;
      }
      if (this.disable) return
      this.clicked = true
    },
    closeModal() {
      this.clicked = false
    },
    acceptEvent() {
      this.loading = true
      this.event()?.then(({data}) => {
        this.loading = false;
        this.successEvent(data)
      }).catch((e) => {
        this.loading = false
        this.errorEvent(e)
      })
    },
    confirmClick(value) {
      this.closeModal()
      if (value) this.acceptEvent()
    }
  }
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.24, 0, .25, 1);

.button-container {
  position: relative;
  margin-top: 40px;
  background: transparent;

  .button-container-content {
    @include center-children;
    Width: 192px;
    Height: 60px;
    background: $base-color;
    border-radius: 58px;
    cursor: pointer;
    position: relative;
    @media (max-height: 759px) {
      Width: 152px;
      Height: 55px;
    }
    @media only screen and (max-width: 767px) {
      padding: 13px 22px;
      border-radius: 50px;
    }
  }

  &.active {
    .confirmation-container {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .confirmation-container {
    @include center-children;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.9);
    transition: all .4s $ease;

    .confirmation-content {
      @include flex;
      flex-direction: column;
      padding: 30px;
      border-radius: 10px;
      background: #0F0E15;

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: $base-color;
      }

      .confirmation-button-container {
        @include flex;
        margin-top: 30px;

        .confirmation-button {
          @include center-children;
          height: 40px;
          width: 80px;
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 10px;
          font-size: 20px;
          color: #FFFFFF;
          transition: all .25s $ease;
          cursor: pointer;

          &:hover {
            transform: translateY(-5px);
            will-change: transform;
            box-shadow: 0 14px 12px 2px rgb(66 232 224 / 8%);
          }

          &.yes {
            background: $base-color;
            border: 1px solid transparent;
            margin-left: 20px;
            color: #1E1E1E;
          }
        }
      }
    }
  }

  &.disable {
    .button-container-content {
      background: #676767;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .loading {
    width: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    animation: rotate 2s infinite linear;
  }

  .button-title {
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 15px;
    }
  }

  .image {
    margin-left: 10px;
  }

  @keyframes rotate {
    0% {
      transform: translateY(-50%) rotate(0deg);
    }
    100% {
      transform: translateY(-50%) rotate(359deg);
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .35s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}
</style>