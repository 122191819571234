<template>
  <div class="step-3 under-review">
    <h2 class="header">Upgrade Under Review</h2>
    <span
        class="description">Our team will review your information and contact soon</span>
    <svg width="66" height="96" class="image" a viewBox="0 0 66 96" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
          d="M32.8224 67.2852C32.155 69.3197 29.7512 71.1263 26.1123 72.2591C16.8702 75.1354 11.1519 79.2382 10.0449 83.6961H55.5999C54.4922 79.2374 48.7746 75.1347 39.5325 72.2591C35.8936 71.1264 33.4897 69.3197 32.8224 67.2852Z"
          fill="white"/>
      <path
          d="M13.1484 21.3345C15.9032 26.5817 20.3136 31.2791 26.1108 34.9863C29.7081 37.2736 32.1514 40.9939 32.821 45.2043C33.4906 40.9939 35.934 37.2736 39.5311 34.9863C45.3284 31.2791 49.7389 26.5817 52.4935 21.3345H13.1484Z"
          fill="#42E8E0"/>
      <path
          d="M43.068 40.5154C54.8136 33.0059 62.3617 21.3249 62.3617 8.20548C63.232 8.20548 64.0673 7.85967 64.6827 7.24427C65.2981 6.62887 65.6439 5.79365 65.6439 4.92329V0H0V4.71811C0.000732586 5.60679 0.340672 6.46178 0.950954 7.10867C1.5605 7.75485 2.39497 8.14314 3.28225 8.19517V8.20543C3.28225 21.3261 10.8298 33.0057 22.5759 40.5153C27.7374 43.8152 27.7374 51.367 22.5759 54.6668C10.8304 62.1763 3.28225 73.8574 3.28225 86.9768V86.987C2.39503 87.039 1.56056 87.4273 0.950954 88.0735C0.340672 88.7204 0.00073146 89.5754 0 90.4641V95.1822H65.6439V90.2589C65.6439 89.3885 65.2981 88.5533 64.6827 87.9379C64.0673 87.3225 63.232 86.9767 62.3617 86.9767C62.3617 73.856 54.8141 62.1764 43.068 54.6668C37.9065 51.367 37.9065 43.8151 43.068 40.5153V40.5154ZM35.9145 47.5912C35.9043 51.5775 37.9365 55.292 41.2994 57.432C52.5987 64.6566 59.0789 75.4256 59.0789 86.9774H6.56382C6.56382 75.4252 13.044 64.6566 24.3434 57.432C27.6982 55.2847 29.7282 51.5753 29.7282 47.5912C29.7282 43.6072 27.6982 39.8978 24.3434 37.7505C13.044 30.5259 6.56382 19.7569 6.56382 8.20511H59.0789C59.0789 19.7573 52.5987 30.5259 41.2994 37.7505C37.9365 39.8905 35.9042 43.605 35.9145 47.5912Z"
          fill="white"/>
      <path
          d="M32.8217 54.0512C33.2298 54.0512 33.621 53.8892 33.9097 53.6006C34.1984 53.3119 34.3602 52.9207 34.3602 52.5126V50.8745C34.3602 50.0246 33.6716 49.3359 32.8217 49.3359C31.9719 49.3359 31.2832 50.0246 31.2832 50.8745V52.5126C31.2832 53.3625 31.9719 54.0512 32.8217 54.0512Z"
          fill="#42E8E0"/>
      <path
          d="M31.2832 60.7167C31.2832 61.5666 31.9719 62.2553 32.8217 62.2553C33.6716 62.2553 34.3603 61.5666 34.3603 60.7167V59.0786C34.3603 58.2287 33.6716 57.54 32.8217 57.54C31.9719 57.54 31.2832 58.2287 31.2832 59.0786V60.7167Z"
          fill="#42E8E0"/>
    </svg>
    <span class="review">Review expected to be completed</span>
    <span class="time">In three working days</span>
<!--    <Button :event="goDashboard" :showModal="false" title="Ok"/>-->
  </div>
</template>

<script>
// import Button from "@/components/Button";

export default {
  components: {
    // Button
  },
  methods: {
    goDashboard() {
      return this.$router.push({path: '/'})
    }
  }
}
</script>

<style lang="scss" scoped>
.step-3 {
  @include flex;
  flex-direction: column;
  padding: 0 92px;
  @media only screen and (max-width: 767px) {
    padding: 0 20px;
  }

  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    color: $base-color;
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      font-size: 35px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 1023px) and(min-width: 768px) {
      font-size: 25px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  .description {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 27px;
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media only screen and (max-width: 1365px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .image {
    margin-top: 38px;
    height: auto;
    @media only screen and (max-width: 1023px) {
      margin-top: 25px;
      width: 40px;
    }
  }

  .review {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 38px;
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 1365px) {
      font-size: 16px;
      margin-top: 25px;
    }
  }

  .time {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: $base-color;
    line-height: 40px;
    margin-top: 12px;
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media only screen and (max-width: 1365px) {
      font-size: 22px;
      line-height: 25px;
    }
  }
}
</style>