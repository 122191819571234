import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import WelcomeToFortchain from '../views/WelcomeToFortchain'
import VerificationSteps from '../views/VerificationSteps'
import HedgeFund from '../components/dashboard/HedgeFund'
import CryptoHedgeFund from '../components/dashboard/CryptoHedgeFund'
import Staking from '../components/dashboard/Staking'
import Settings from '../components/dashboard/Settings'
import LoadingComponent from '../components/dashboard/LoadingComponent'
import {Auth} from 'aws-amplify';
import store from '../store/index'

Vue.use(VueRouter)
const lazyLoadView = ({component, loading, error}) => {
    const AsyncHandler = () => ({
        component,
        loading,
        error,
    });

    return () =>
        Promise.resolve({
            functional: true,
            render(h, {data, children}) {
                return h(AsyncHandler, data, children);
            }
        });
};

const HomeLazy = lazyLoadView({
    component: import("../views/Home"),
    loading: LoadingComponent,
});
const DashboardLazy = lazyLoadView({
    component: import("../components/dashboard/Index"),
    loading: LoadingComponent,
});
const ReceiveLazy = lazyLoadView({
    component: import("../components/dashboard/Receive"),
    loading: LoadingComponent,
});
const WithdrawLazy = lazyLoadView({
    component: import("../components/dashboard/Withdraw"),
    loading: LoadingComponent,
});

const routes = [
    {
        path: '/',
        component: HomeLazy,
        meta: {requiresAuth: true, requiresVerification: true},
        children: [
            {
                name: '',
                path: '/',
                component: DashboardLazy
            },
            {
                name: 'deposit',
                path: 'deposit',
                component: ReceiveLazy
            },
            {
                name: 'withdraw',
                path: 'withdraw',
                component: WithdrawLazy
            },
            {
                name: 'staking',
                path: 'staking',
                component: Staking
            },
            {
                name: 'settings',
                path: 'settings',
                component: Settings
            },
            {
                name: 'hedgeFund',
                path: 'hedge-fund',
                component: HedgeFund
            },
            {
                name: 'cryptoHedgeFund',
                path: 'crypto-hedge-fund',
                component: CryptoHedgeFund
            },

        ]
    },
    {
        path: '/sign-in',
        name: 'Sign-in',
        component: SignIn,
        meta: {guest: true},
    },
    {
        path: '/sign-up',
        name: 'Sign-up',
        component: SignUp,
        meta: {guest: true},
    },
    {
        path: '/welcome',
        name: 'welcomeToFortchain',
        component: WelcomeToFortchain,
        meta: {guest: true},
    },
    {
        path: '/verification',
        name: 'Verification',
        component: VerificationSteps,
        meta: {requiresAuth: true, verify: true}
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

function setUser(newUser) {
    let user = store.getters["auth/getUser"]

    if (!user) {
        store.commit('auth/SET_USER', newUser)
    }
}

function userExistNavigation(to, from, next, user) {
    if (to.matched.some(record => record.meta.verify) && user.signInUserSession.accessToken.payload['cognito:groups']?.includes('KYC')) {
        next({
            path: '/',
        })
    }
    if (to.matched.some(record => record.meta.requiresVerification) && !user.signInUserSession.accessToken.payload['cognito:groups']?.includes('KYC')) {
        next({
            path: '/verification',
        })
    }
    setUser(user)
    if (!user) {
        next({
            path: '/sign-in',
        })
    } else {
        next()
    }
}

function AuthNavigationGuard(to, from, next) {
    const user = store.getters["auth/getUser"];
    if (user) {
        userExistNavigation(to, from, next, user)
        return
    }

    Auth.currentAuthenticatedUser().then(user => {
        userExistNavigation(to, from, next, user)
    }).catch(() => {
        next({
            path: '/sign-in',
        })
    })

}

function guestNavigationGuard(to, from, next) {
    // const user = store.getters["auth/getUser"];
    // if (user) userExistGustRouteNavigation(to, from, next, user)

    Auth.currentAuthenticatedUser().then(user => {
        userExistGustRouteNavigation(to, from, next, user)
    }).catch(() => {
        next()
    })
}

function userExistGustRouteNavigation(to, from, next, user) {
    setUser(user)
    if (user) {
        next({
            path: '/',
        })
    } else {
        next()
    }
}


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            // verificationGuard(to, from, next)
        }
        AuthNavigationGuard(to, from, next)
    } else {
        guestNavigationGuard(to, from, next)
    }
})
export default router
