import Vue from "vue";
import unionBy from 'lodash/unionBy'

let $vm = Vue.prototype

export default {
    namespaced: true,
    state: () => ({
        stakePackages: [],
        stakes: [],
        stakeTransaction: []
    }),
    actions: {
        getStakePackages({commit}) {
            return $vm.$axios.get('api/v1/stake/package').then(({data}) => {
                commit('SET_STAKE_PACKAGES', data)
            })
        },
        getStakes({commit}) {
            return $vm.$axios.get('api/v1/stake?page=0&size=100').then(({data}) => {
                commit('SET_STAKES', data?.content)
            })
        },
        getStakeTransaction({commit}) {
            return $vm.$axios.get('api/v1/stake/transactions').then(({data}) => {
                commit('SET_STAKE_TRANSACTION', data)
            })
        },
        createStake({commit}, data) {
            return $vm.$axios.post('api/v1/stake', data, {
                openModal: true,
                title: 'stake created successfully'
            }).then((resp) => {
                commit('SET_STAKES', resp.data)
            })
        },
        withdrawStake({commit}, id) {
            return $vm.$axios.post(`api/v1/stake/${id}/withdraw`, {}, {
                openModal: true,
                title: 'withdraw request successfully created'
            }).then((resp) => {
                commit('SET_STAKES', resp.data)
            })
        },
        ReStake({commit}, id) {
            return $vm.$axios.post(`api/v1/stake/${id}/compound`, {}, {
                openModal: true,
                title: 'restake request successfully'
            }).then((resp) => {
                commit('SET_STAKES', resp.data)
            })
        },
    },
    mutations: {
        SET_STAKE_PACKAGES(state, packages) {
            state.stakePackages = packages.map(item => ({
                ...item,
                durationInDays: Math.round(item.durationInDays / 30),
                interest: item.interest * 12
            }))
        },
        SET_STAKES(state, stakes) {
            stakes instanceof Array ? state.stakes = stakes : state.stakes = unionBy([stakes], state.stakes, 'id')
        },
        SET_STAKE_TRANSACTION(state, stakes) {
            state.stakeTransaction = stakes.map(item => ({
                ...item,
                createdAt: $vm.moment(item.createdAt).format('DD/MM/YYYY'),
                status: item.transactionStatus,
                icon: require(`@/assets/images/${item.transactionStatus?.toLowerCase()}.png`)
            }))
        },
    },
    getters: {
        getStakePackages(state) {
            return state.stakePackages
        },
        getStakes(state) {
            return state.stakes
        },
        getStakeTransaction(state) {
            return state.stakeTransaction
        }
    }
}