<template>
  <div class="fund-table">
    <div class="transaction-header-container">
      <div class="tabs-list">
        <div :class="{active:activeTabIndex===0}" @click="activeTabIndex=0" class="item ">{{ $tc('transaction') }}</div>
        <div :class="{active:activeTabIndex===1}" @click="activeTabIndex=1" class="item">{{ $tc('rewards') }}</div>
        <div :class="{active:activeTabIndex===2}" @click="activeTabIndex=2" class="item">{{ $tc('pools') }}</div>
      </div>
    </div>
    <v-data-table
        :headers="headers"
        :items="activeTabTransaction"
        :items-per-page="5"
        hide-default-footer
        class="elevation-1"
        hide-default-header
    >
      <template v-slot:header="{ props }">
        <th v-for="(head,index) in props.headers"
            :key="index">
          <div class="header-item">
            <img :src="require(`@/assets/images/${head.iconName}.png`)" alt="">
            <span class="title">  {{ head.text.toUpperCase() }}</span>
          </div>
        </th>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="table-flex">
          <img :src="item.icon" alt="">
          <span class="title">{{ item.status }}</span>
        </div>
      </template>
      <template v-slot:item.amount="{ item }">
        <div class="table-flex">
          <span>{{ item.amount }} {{ item.balanceType }}</span>
        </div>
      </template>
      <template v-slot:item.reward="{ item }">
        <div class="table-flex">
          <span>{{ item.reward }} {{ item.type }}</span>
        </div>
      </template>
      <template v-slot:item.balance="{ item }">
        <div class="table-flex">
          <span>{{ item.balance }} {{ item.balanceType }}</span>
        </div>
      </template>
      <template v-slot:item.tvl="{ item }">
        <div class="table-flex">
          <span>{{ item.tvl }} DFORT</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    isCrypto: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTabIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      investment: 'funds/getInvestment',
      fundRewards: 'funds/getFundRewards',
      funds: 'funds/getFunds'
    }),
    headers() {
      switch (this.activeTabIndex) {
        case 0:
          return [
            {
              text: this.$tc('status'),
              sortable: false,
              value: 'status',
              iconName: 'status'
            },
            {
              text: this.$tc('amount'),
              value: 'amount',
              sortable: false,
              iconName: 'amount'
            },
            {
              text: this.$tc('startDate'),
              value: 'startDate',
              sortable: false,
              iconName: 'date'
            },
          ]
        case 1:
          return [
            {
              text: this.$tc('status'),
              sortable: false,
              value: 'status',
              iconName: 'status'
            },
            {
              text: this.$tc('reward'),
              value: 'reward',
              sortable: false,
              iconName: 'amount'
            },
            {
              text: this.$tc('date'),
              value: 'createdAt',
              sortable: false,
              iconName: 'date'
            },
          ]
        default:
          return [
            {
              text: this.$tc('name'),
              value: 'name',
              sortable: false,
              iconName: 'name'
            },
            {
              text: this.$tc('tvl'),
              value: 'tvl',
              sortable: false,
              iconName: 'tvl'
            },
            {
              text: this.$tc('startDate'),
              value: 'startDate',
              sortable: false,
              iconName: 'date'
            },
          ]
      }
    },
    activeTabTransaction() {
      return this.activeTabIndex === 0 ? this.investment : this.activeTabIndex === 1 ? this.fundRewards : this.funds
    }
  },
  methods: {
    ...mapActions({
      getInvestment: 'funds/getInvestment',
      getFundRewards: 'funds/getFundRewards',
      getFunds: 'funds/getFunds',
    })
  },
  created() {
    this.getInvestment(this.isCrypto)
    this.getFundRewards(this.isCrypto)

  }
}
</script>

<style lang="scss" scoped>
.fund-table {
  padding-top: 20px;

  .transaction-header-container {
    @include flex;
    margin-bottom: 31px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 10px;
    }

    .tabs-list {
      @include flex;
      background: #171717;
      border-radius: 10px;
      width: fit-content;

      .item {
        border-radius: 10px;
        font-weight: 400;
        font-size: 14px;
        color: white;
        padding: 15px 30px;
        transition: all .4s ease;
        cursor: pointer;

        &.active {
          background: $base-color;
          color: black;
        }
      }
    }

    .header-title {
      font-weight: 300;
      font-size: 25px;
      text-transform: capitalize;
      color: #FFFFFF;
      display: block;
    }

  }


  ::v-deep .v-data-table {
    background: #1e1e1e;
    @media only screen and (max-width: 1023px) {
      background: #171717;
    }

    &.v-data-table--mobile {
      tbody {
        @media only screen and (max-width: 599px) {
          display: unset !important;
        }
      }

      .v-data-table-header-mobile {
        display: none;
      }

      .v-data-table__mobile-row__header {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #FFFFFF;
      }

      .v-data-table__mobile-row__cell {
        color: white;

        .title {
          color: white;
        }
      }

      .v-data-table__mobile-table-row {
        background: transparent;
        display: block;
        border: 1px solid rgba(250, 250, 250, .2);
        margin-top: 15px;
        border-radius: 10px;
        height: unset
      }
    }

    th {
      padding: 15px 30px;
      background: #171717;
      border-bottom: 3px solid #1e1e1e;

      &:nth-child(2) {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      &:last-of-type {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .header-item {
        @include flex;

        img {
          width: 24px;
        }

        .title {
          color: white;
          margin-left: 10px;
          font-size: 16px;
        }
      }
    }

    .text-start {
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      font-family: "Rubik";
    }

    .v-data-table-header {
      span {
        font-weight: 500;
        font-size: 22px;
        color: #FFFFFF;
        font-family: "Rubik";
        text-align: left;
      }
    }

    tr {
      height: 52px;
      background: #171717;
      @media only screen and (max-width: 1023px) {
        background: #1e1e1e;
      }

      &:hover {
        background: #171717 !important;
        @media only screen and (max-width: 1023px) {
          background: #1e1e1e;
        }
      }

      td, th {
        margin-bottom: 13px;
        border-bottom: 3px solid #1e1e1e !important;
        padding: 15px 30px;
        @media only screen and (max-width: 1023px) {
          border-bottom: 3px solid #171717 !important;
        }

        &:first-child {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }

        &:last-child {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }

      .table-flex {
        @include flex;

        .title {
          margin-left: 10px;
        }
      }
    }

    .v-data-table__empty-wrapper {
      td {
        padding: 50px 0;
        color: white;
        font-size: 22px;
      }
    }
  }
}
</style>