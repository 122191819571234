import Vue from "vue";

let $vm = Vue.prototype

export default {
    namespaced: true,
    state: () => ({
        balance: {},
        wallet: {},
        walletTransaction: []
    }),
    actions: {
        getWallet({commit}) {
            return $vm.$axios.get('api/v1/wallet').then(({data}) => {
                commit('SET_WALLET', data)
            })
        },
        getBalance({commit}) {
            return $vm.$axios.get('api/v1/wallet/balance').then(({data}) => {
                commit('SET_BALANCE', data)
            })
        },
        getWalletTransaction({commit}) {
            return $vm.$axios.get('api/v1/wallet/transactions').then(({data}) => {
                commit('SET_WALLET_TRANSACTION', data)
            })
        },
        withdrawWallet({commit}, {amount, address, token}) {
            return $vm.$axios.post('api/v1/wallet/balance/withdraw', {
                amount: Number(amount),
                address: address,
                token: token
            }, {openModal: true}).then(() => {
                console.log(commit)
            })
        },
    },
    mutations: {
        SET_BALANCE(state, balance) {
            state.balance = balance
        },
        SET_WALLET(state, wallet) {
            state.wallet = wallet
        },
        SET_WALLET_TRANSACTION(state, transaction) {
            state.walletTransaction = transaction.map(item => ({
                ...item,
                createdAt: $vm.moment(item.createdAt).format('DD/MM/YYYY'),
                status: item.transactionStatus,
                icon: require(`@/assets/images/${item.transactionStatus?.toLowerCase()}.png`)
            }))
        },
    },
    getters: {
        getBalance(state) {
            return state.balance
        },
        getWalletTransaction(state) {
            return state.walletTransaction
        },
        getWallet(state) {
            return state.wallet
        }
    }
}