export default {
    namespaced: true,
    state: () => ({
        modalInfo: null
    }),
    actions: {
        setModalInfo({commit}, data) {
            commit('SET_MODAL_INFO', data)
        }
    },
    mutations: {
        SET_MODAL_INFO(state, data) {
            state.modalInfo = data
        }
    },
    getters: {
        getModalInfo(state) {
            return state.modalInfo
        }
    }
}