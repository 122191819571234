export default {
    namespaced: true,
    state: () => ({
        steps: [
            {
                name: 'step1',
                id: 0,
            },
            {
                name: 'step2',
                id: 1,
            },
            {
                name: '3',
                id: 2,
            },
        ],
        activeStepId: 0
    }),
    actions: {
        setActiveStepId({commit}, id) {
            commit('SET_ACTIVE_STEP_ID', id)
        }
    },
    mutations: {
        SET_ACTIVE_STEP_ID(state, id) {
            state.activeStepId = Number(id)
        }
    },
    getters: {
        getActiveStepId(state) {
            return state.activeStepId

        },
        getSteps(state) {
            return state.steps
        }
    }
}