<template>
  <div class="verification-steps">
    <Header/>
    <div class="verification-content-container">
      <h2 class="header-title">Verification</h2>
      <div class="progress-bar">
        <div class="step active">
          <div class="dot-container">
            <span class="step-title">personal info</span>
            <div class="dot">
              <div class="inside"></div>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div :class="{active:stepInfo}"
             class="step">
          <div class="dot-container">
            <span class="step-title">ID Verification</span>
            <div class="dot">
              <div class="inside"></div>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div :class="{active:stepInfo && activeImageStep===1||activeStepId>=2}"
             class="step">
          <div class="dot-container">
            <span class="step-title">self Verification</span>
            <div class="dot">
              <div class="inside"></div>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div :class="{active:activeStepId>=2}" class="step">
          <div class="dot-container">
            <span class="step-title">Under Review</span>
            <div class="dot">
              <div class="inside"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="step-content">
        <transition-group name="list" tag="div">
          <step-one key="step1" v-if="!stepInfo && activeStepId===0"
                    @updated="updateStepOneInfo"
                    :stepInfo="stepInfo"/>
          <template v-for="(item,index) in imageTypes">
            <step-two v-if="stepInfo && activeStepId===1 && activeImageStep===index"
                      @uploaded="updateStepInfo"
                      :type="item" :key="item"
                      :stepInfo="stepInfo"/>
          </template>
          <step-three key="step3" v-if="stepInfo && activeStepId===2"/>
        </transition-group>
      </div>
    </div>
    <div :class="{active:loading}" class="verification-loader">
      <div class="col-sm-2">
        <div class="sp sp-circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import StepOne from "../components/StepOne";
import StepTwo from "../components/StepTwo";
import StepThree from "../components/StepThree";
import {mapActions, mapGetters} from "vuex";
import unionBy from 'lodash/unionBy'

export default {
  data() {
    return {
      stepInfo: null,
      imageTypes: [],
      loading: false
    }
  },
  components: {
    StepOne,
    StepTwo,
    StepThree,
    Header
  },
  computed: {
    ...mapGetters({activeStepId: 'steps/getActiveStepId'}),
    activeImageStep() {
      return !this.stepInfo.kycImageList?.length ? 0 : 1
    }
  },
  methods: {
    ...mapActions({setActiveStepId: 'steps/setActiveStepId'}),
    getVerificationInfo() {
      this.loading = true
      this.$axios.get('api/v1/kyc').then(({data}) => {
        this.stepInfo = data
        this.loading = false
        this.setActiveSteps(data)
      }).catch((error) => {
        this.loading = false
        if (error.response?.status === 404) {
          this.setActiveSteps({})
        }
      })
    },
    setActiveSteps(data) {
      let activeStepId = 0

      if (data.status === "INITIATED" || data.status === "CANCELLED") activeStepId = 1
      if (data.status === "PENDING") activeStepId = 2

      this.setActiveStepId(activeStepId)
    },
    getImageType() {
      this.$axios.get('api/v1/kyc/image/type').then(({data}) => {
        this.imageTypes = data
      })
    },
    updateStepInfo({data, type}) {
      this.$set(this.stepInfo, 'kycImageList', unionBy(this.stepInfo?.kycImageList, [data], 'id'))
      let typeIndex = 1 - this.imageTypes?.indexOf(type)

      let typeImages = this.stepInfo.kycImageList?.filter(item => item.type === this.imageTypes[typeIndex])
      typeImages?.length ? this.setActiveStepId(2) : this.setActiveStepId(1)
    },
    updateStepOneInfo(data) {
      this.stepInfo = {
        ...data
      }
    }
  },
  created() {
    this.getImageType()
    this.getVerificationInfo()
  }
}
</script>

<style lang="scss" scoped>
$ease-in: cubic-bezier(.18, 0, .31, 1);
.verification-steps {
  background: linear-gradient(109.04deg, #02000E 0.52%, #090D23 62.82%, #2C4C8A 90.85%);
  min-height: 100vh;


  .verification-content-container {
    @include flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
    @media (max-width: 767px) {
      width: 100%;
      padding: 0 19px 20px;
    }
  }

  .header-title {
    font-weight: 500;
    font-size: 30px;
    text-transform: capitalize;
    color: #FFFFFF;
    display: block;
    width: fit-content;
    margin: 50px auto 0;
    @media only screen and (max-width: 767px) {
      font-size: 22px;
      margin: 25px auto 0;
    }
  }

  .progress-bar {
    @include flex;
    margin-top: 81px;
    margin-bottom: 49px;
    width: fit-content;
    @media only screen and (max-width: 1023px) {
      width: 90%;
      margin-top: 61px;
      margin-bottom: 25px;
    }

    .step {
      @include flex;
      @media only screen and (max-width: 1023px) {
        width: 33.3%;
      }

      &:last-child {
        @media only screen and (max-width: 1023px) {
          width: fit-content;
        }

        .step-title {
          right: -10px;
          left: unset !important;
          transform: unset !important;
        }
      }

      &:first-child {
        .step-title {
          left: -10px !important;
          transform: unset !important;
        }
      }

      &.active {
        .line {
          background: $base-color !important;
        }

        .step-title {
          color: $base-color !important;
        }

        .dot {
          background: $base-color !important;

          .inside {
            background: #151628 !important;
          }
        }
      }

      .dot-container {
        position: relative;

        .step-title {
          position: absolute;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: white;
          text-transform: capitalize;
          left: 50%;
          transform: translateX(-50%);
          top: -31px;
          transition: all .4s $ease-in;
          width: max-content;

          @media only screen and (max-width: 500px) {
            font-weight: 300;
            font-size: 9px;
          }

        }

        .dot {
          @include center-children;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          background: #151729;
          transition: all .4s $ease-in;

          .inside {
            width: 10px;
            height: 10px;
            background: #FFFFFF;
            border-radius: 50%;
            transition: all .4s $ease-in;
          }
        }

      }

      .line {
        width: 300px;
        height: 1px;
        background: #FFFFFF;
        transition: all .4s $ease-in;
        @media only screen and (max-width: 1440px) and (min-width: 1366px) {
          width: 250px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          width: 200px;
        }
        @media only screen and (max-width: 1023px) {
          width: 100%;
        }
      }

    }
  }

  .step-content {
    width: 100%;
  }

  .verification-loader {
    @include center-children;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    background: linear-gradient(109.04deg, #02000E 0.52%, #090D23 62.82%, #2C4C8A 90.85%);
    z-index: 2;
    transition: all .4s .1s $ease-in;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
    }

    .col-sm-2 {
      padding: 10px;
      border-radius: 4px;
      height: 125px;
      margin-bottom: 10px;
    }

    .sp-circle {
      width: 32px;
      height: 32px;
      clear: both;
      margin: 20px auto;
      border: 4px transparent solid;
      border-top: 4px white solid;
      border-radius: 50%;
      -webkit-animation: spCircRot 0.6s infinite linear;
      animation: spCircRot 0.6s infinite linear;
    }

    @keyframes spCircRot {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }

  }

  .list-enter-active {
    transition: all .4s .4s;
  }

  .list-leave-active {
    transition: all .1s;
  }

  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }
}
</style>