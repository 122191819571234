<template>
  <div class="step-1 personal-info">
    <div class="form">
      <div class="select-inner">
        <span class="title">Gender</span>
        <v-select :clearable="false"
                  v-model="gender"
                  :options="genderOptions">
        </v-select>
      </div>
      <div class="select-inner">
        <span class="title">Citizenship</span>
        <v-select :clearable="false"
                  :searchable="true"
                  v-model="citizenship"
                  :options="countries">
        </v-select>
      </div>
      <form-input v-for="item in inputs"
                  :name="item.name"
                  :key="item.name"
                  :title="item.title"
                  :error="item.error"
                  @onkeyEvent="inputKeyEvent"
                  v-model="item.value"
                  :type="item.type"/>
    </div>
    <span class="error-text">{{ error }}</span>
    <Button :event="kycFormSubmit"
            :showModal="false"
            :errorEvent="errorEvent"
            :successEvent="formSuccess"
            :disable="!allowClick" title="Next"/>
  </div>
</template>

<script>
import FormInput from "@/components/FormInput";
import Button from "@/components/Button";
import {mapActions} from "vuex";
import vSelect from 'vue-select'
import countries from "../scripts/countries";

export default {
  props: {
    stepInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      inputs: [
        {
          type: "text",
          name: "address",
          title: "Address",
          value: '',
          error: false
        },
        {
          type: "text",
          name: "zipCode",
          title: "Zip code",
          value: '',
          error: false
        },
      ],
      allowClick: true,
      loading: false,
      error: '',
      gender: 'Male',
      citizenship: '',
      genderOptions: ['Male', 'Female']
    }
  },
  components: {
    FormInput,
    Button,
    vSelect
  },
  computed: {
    countries() {
      return countries?.map(item => ({
        label: item.name,
        code: item.code
      }))
    }
  },
  methods: {
    ...mapActions({setActiveStepId: 'steps/setActiveStepId'}),
    inputKeyEvent() {

    },
    formSuccess(data) {
      this.$emit('updated', data)
      this.setActiveStepId(1)
    },
    errorEvent(error) {
      this.error = error?.data?.error
    },
    kycFormSubmit() {
      let data = {}
      this.inputs.forEach(item => {
        data[item.name] = item.value
      })
      this.loading = true
      data.citizenship = this.citizenship?.label
      data.gender = this.gender

      return this.$axios.post('api/v1/kyc', data)
    },
    kycFormUpdate(data) {
      this.$axios.put(`api/v1/kyc/${this.stepInfo?.id}`, data).then(({data}) => {
        this.formSuccess(data)
        this.loading = false
      }).catch(e => {
        this.loading = false
        console.log(e)
      })
    },
    setStepInfo() {
      this.inputs.forEach(item => {
        item.value = this.stepInfo?.[item.name]
      })
    },
    submitError(error) {
      this.loading = false
      this.error = error.message
      this.inputs.forEach(item => item.value = '')
    }
  },
  mounted() {
    this.setStepInfo()
  }
}
</script>

<style lang="scss" scoped>
.step-1 {
  .form {
    @include flex;
    flex-wrap: wrap;
    @media (max-width: 1023px) and (min-width: 768px) {
      width: 80%;
      margin: 0 auto;
    }
    @media (max-width: 767px) {
      width: 100%;
      display: block;
    }

    .form-input {
      flex: calc(50% - 20px);
      margin-top: 40px;
      @media (max-width: 767px) {
        flex: 100%;
        margin-top: 25px;
      }

      &:nth-child(2n+1) {
        margin-right: 20px;
        @media (max-width: 767px) {
          margin-right: unset;
        }
      }
    }
  }

  .error-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #ED2E2E;
    margin-top: 18px;
  }

  .select-inner {
    flex: calc(50% - 20px);
    margin-top: 40px;
    @media (max-width: 767px) {
      flex: 100%;
      margin-top: 25px;
    }

    &:nth-child(2n+1) {
      margin-right: 20px;
      @media (max-width: 767px) {
        margin-right: unset;
      }
    }

    .title {
      font-weight: 500;
      font-size: 20px;
      color: #FFFFFF;
      display: block;
      font-family: 'Rubik', 'Roboto', sans-serif;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    ::v-deep .v-select {
      width: 100%;
      height: 60px;
      background: rgba(7, 9, 29, 0.8);
      border: 0.5px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 58px;
      margin-top: 17px;
      padding-left: 33px;
      @media only screen and (max-width: 767px) {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
      }

      img {
        height: 30px;
        width: 30px;
        object-fit: contain;
      }

      .title {
        color: white;
        font-size: 18px;
        margin-left: 10px;
        margin-bottom: 0;
      }

      .vs__dropdown-toggle {
        width: 100%;
        height: 100%;
        border: unset;
        padding: 0;

        .vs__selected-options {
          flex-grow: unset;
          flex-wrap: unset;
          padding: 0;
          flex-basis: unset;
          width: 100%;
        }

        .vs__selected {
          width: 100%;
          height: 100%;
          border: unset;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #FFFFFF;
          font-family: "Rubik";
          padding: 0;
          margin: 0;
          @media only screen and (max-width: 767px) {
            justify-content: flex-start;
            padding-left: 20px;
          }
        }

        input {
          color: white;
          //display: none;
        }
      }

      .vs__dropdown-menu {
        background: transparent;
        border: 1px solid #1e1e1e;
        padding: 0;
        border-top: unset;

        li {
          @include flex;
          padding: 5px 10px 5px 10px;
          background: black;
          color: white;

          &:first-child {
            margin-top: 0;
          }

          &:hover, &.vs__dropdown-option--selected, &.vs__dropdown-option--highlight {
            background: white;
            color: black;

            .title {
              color: #0F0E15;
            }
          }

          .title {
            color: white;
            margin-left: 10px;
          }
        }
      }

      .vs__actions {
        //display: none;
        padding: 10px;

        svg {
          path {
            fill: white
          }
        }
      }
    }

  }

  .button-container {
    margin: 70px auto 0;
    width: fit-content;
    @media (max-width: 767px) {
      margin: 40px auto 0;
    }
  }
}
</style>