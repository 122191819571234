import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import en from './languages/en.json'
import ru from './languages/ru.json'

Vue.config.productionTip = false
import lineClamp from 'vue-line-clamp'

Vue.use(lineClamp, {})
import axios from './scripts/api'

Vue.use(axios)

import VueCollapse from 'vue2-collapse'

Vue.use(VueCollapse)

import Amplify from 'aws-amplify';
import awsconfig from '@/scripts/aws-exports';

Amplify.configure(awsconfig);
import vuetify from '@/plugins/vuetify'

import moment from 'moment'

Vue.prototype.moment = moment

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        ru
    }
})

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
    i18n,
}).$mount('#app')
