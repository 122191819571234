import Vuex from 'vuex'
import Vue from 'vue'
import auth from './modules/auth'
import steps from './modules/steps'
import wallet from './modules/wallet'
import stake from './modules/stake'
import modal from './modules/modal'
import userSettings from './modules/userSettings'
import funds from './modules/funds'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        steps,
        wallet,
        stake,
        modal,
        userSettings,
        funds
    },
})
