<template>
  <header class="header">
    <router-link to="/" class="logo-container">
      <div class="img-container">
        <img src="@/assets/images/fortchain_new.png" class="image" alt="">
      </div>
      <span class="title">Fortchain</span>
    </router-link>
  </header>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.header {
  @include center-children;
  background: transparent;
  z-index: 1;
  padding: 52px 8vw 0;

  @media only screen and (max-width: 1366px) and (min-width: 1024px) {
    padding: 38px 5vw 0;
  }
  @media only screen and (max-width: 1023px) {
    padding-top: 20px;
  }

  .logo-container {
    @include flex;
    text-decoration: none;

    @media only screen and (max-width: 1023px) {
      display: none;
    }

    .img-container {
      @include center-children;
      border: 3px solid #1280A0;
      border-radius: 50%;
      height: 70px;
      width: 70px;

      img {
        width: 25px;
      }
    }

    .title {
      margin-left: 20px;
      font-weight: 400;
      font-size: 30px;
      color: white;
    }

    padding: 0 13px 0 0;
  }

}
</style>