<template>
  <div class="staking-table">
    <div class="tabs-list">
      <div :class="{active:activeTabIndex===0}" @click="activeTabIndex=0" class="item ">{{ $tc('active') }}</div>
      <div :class="{active:activeTabIndex===1}" @click="activeTabIndex=1" class="item">{{ $tc('archive') }}</div>
    </div>
    <div class="staking-list">
      <v-collapse-group ref="my_group">
        <v-collapse-wrapper v-for="item in activeTabStakeData"
                            @onStatusChange="activeCollapse"
                            :key="item.id">
          <div class="staking-item">
            <div v-collapse-toggle class="staking-item-header">
              <div class="status staking-flex">
                <img :src="item.statusIcon" class="image" alt="">
                <span class="title">{{ $tc(item.status.toLowerCase()) }}</span>
              </div>
              <div class="staking-flex">
                <img :src="item.icon" class="image" alt="">
                <span class="title">{{ item.stakeType }}</span>
              </div>
              <div class="value-container interest">
                <div class="item">
                  <span class="month">apr</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <img v-bind="attrs"
                           v-on="on"
                           src="../../assets/images/question.png" alt="">
                    </template>
                    <span>apr is annual profit rate</span>
                  </v-tooltip>
                </div>
                <div class="item">
                  <span class="title">{{ item.apyMin }}% - {{ item.apyMax }}%</span>
                </div>
              </div>
              <div class="value-container balance">
                <div class="item">
                  <span class="month">{{ $tc('balance') }} DFORT</span>
                </div>
                <div class="item">
                  <span class="title">{{ item.amount }}</span>
                </div>
              </div>
              <div class="value-container earned">
                <div class="item">
                  <span class="month">{{ $tc('reward') }} DFORT</span>
                </div>
                <div class="item">
                  <span class="title">{{ item.reward }} </span>
                </div>
              </div>
              <div class="staking-flex details">
                <span class="title">{{ $tc('details') }}</span>
                <img src="../../assets/images/select_arrow.png" class="icon" alt="">
              </div>
            </div>
            <div class="staking-item-content" v-collapse-content>
              <div class="upper-content">
                <div class="upper-content-container">
                  <div class="upper-left">
                    <div class="value-container balance">
                      <div class="item">
                        <span class="month">{{ $tc('balance') }} DFORT</span>
                      </div>
                      <div class="item">
                        <span class="title">{{ item.amount }}</span>
                      </div>
                    </div>
                    <div class="value-container">
                      <div class="item">
                        <span class="month">apr</span>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <img v-bind="attrs"
                                 v-on="on"
                                 src="../../assets/images/question.png" alt="">
                          </template>
                          <span>apr is annual profit rate</span>
                        </v-tooltip>
                      </div>
                      <div class="item">
                        <span class="title">{{ item.apyMin }}% - {{ item.apyMax }}%</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="false" class="buttons-container">
                    <Button :event="withdrawStake.bind(null,item.id)"
                            :disable="item.reward===0"
                            :title="$tc('withdraw')"/>
                    <Button :disable="true" :title="$tc('unstake')"/>
                  </div>
                </div>
                <div class="upper-background"></div>
              </div>
              <div class="down-content">
                <div v-for="(item,index) in item.stakeDownContent" :key="index" class="down-content-item">
                  <span :title="item.header" v-line-clamp="1" class="header-title">{{ item.header }}</span>
                  <span :title="item.description" v-line-clamp="1" class="description">{{ item.description }}</span>
                </div>
              </div>
            </div>
          </div>
        </v-collapse-wrapper>
      </v-collapse-group>
    </div>
  </div>
</template>

<script>
import Button from "../Button";
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      activeTabIndex: 0,
    }
  },
  components: {
    Button,
  },
  computed: {
    ...mapGetters({
      stakePackages: 'stake/getStakePackages',
      stakes: 'stake/getStakes'
    }),
    stakeTableData() {
      return this.stakes?.map(item => ({
        ...item,
        stakeType: this.stakePackages?.find(pack => pack.id === item?.stakePackage?.id)?.name || 'stake',
        apyMax: item.stakePackage?.apyMax,
        apyMin: item.stakePackage?.apyMin,
        stakeDownContent: [
          {
            header: this.$tc("created_at"),
            description: this.moment(item.createdAt).format('DD/MM/YYYY')
          },
          {
            header: this.$tc("withdrawal_time"),
            description: `14 ${this.$tc('workingDays')}`
          },
          {
            header: this.$tc("stake_amount"),
            description: '10 000 DFORT'
          },
          {
            header: this.$tc("end_date"),
            description: this.moment(item.endDate).format('DD/MM/YYYY')
          },
        ],
        statusIcon: require(`@/assets/images/${item.status?.toLowerCase()}.png`),
        icon: item?.stakePackage?.id === 1 ? require('../../assets/images/stake.png') : require('../../assets/images/radiation_clock.png')
      }))
    },
    activeStakes() {
      return this.stakeTableData?.filter(item => item.status === 'ACTIVE' || item.status === 'PENDING')
    },
    archiveStakes() {
      return this.stakeTableData?.filter(item => item.status === 'CANCELLED' || item.status === 'FINISHED')
    },
    activeTabStakeData() {
      return this.activeTabIndex === 0 ? this.activeStakes : this.archiveStakes
    }
  },
  methods: {
    ...mapActions({
      withdrawStake: 'stake/withdrawStake',
      ReStake: 'stake/ReStake',
    }),
    activeCollapse(e) {
      let counter = 0;
      this.$refs.my_group.$children.forEach((v) => {
        if (v.status) {
          counter++;
        }
      });
      if (counter == this.$refs.my_group.$children.length) {
        this.toggle = 1;
        this.collapseText = "Contraer";
      } else {
        this.toggle = 0;
        this.collapseText = "Expandir";
      }
      if (e.status) {
        return (e.vm.nodes.toggle.classList.add('active'));
      } else {
        return (e.vm.nodes.toggle.classList.remove('active'))
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.staking-table {
  width: 100%;
  margin-top: 24px;

  .tabs-list {
    @include flex;
    background: #171717;
    border-radius: 16px;
    width: fit-content;

    .item {
      border-radius: 16px;
      font-weight: 400;
      font-size: 14px;
      color: #537090;
      padding: 15px;
      transition: all .4s ease;
      cursor: pointer;

      &.active {
        background: #1B69EB;
        color: white;
      }
    }
  }

  .staking-list {
    width: 100%;
    margin-top: 24px;
    background: #171717;
    border-radius: 36px;
  }

  ::v-deep .vc-collapse {
    border-bottom: 1px solid #000000;
    @media only screen and (max-width: 767px) {
      border: unset;
    }

    &:last-child {
      border-bottom: unset;
    }

    .v-collapse-content {
      max-height: 0;
      transition: max-height 0.3s ease-out;
      overflow: hidden;
      padding: 0;
    }

    .v-collapse-content-end {
      transition: max-height 0.3s ease-in;
      max-height: 250px;
      @media only screen and (max-width: 767px) {
        max-height: 300px;
      }
    }
  }

  .staking-item {
    width: 100%;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    @media only screen and (max-width: 767px) {
      border: 1px solid #FFFFFF;
      border-radius: 20px;
      padding: 25px 17px;
      margin-top: 12px;
    }

    .value-container {
      width: 100%;

      .item {
        @include flex;

        &:last-child {
          margin-top: 10px;
          @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            margin-top: 5px;
          }
          @media only screen and (max-width: 767px) {
            margin-top: 5px;
          }
        }

        .month {
          font-size: 16px;
          color: #FFFFFF;
          font-weight: 300;
          @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            font-size: 14px;
          }
        }


        img {
          margin-left: 16px;
          @media only screen and (max-width: 767px) {
            margin-left: 10px;
          }
        }
      }
    }

    .title {
      font-weight: 500;
      font-size: 20px;
      color: #FFFFFF;
      @media only screen and (max-width: 1800px) and (min-width: 1366px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 400px) {
        font-size: 16px;
      }
    }

    .staking-item-header {
      @include space-between;
      cursor: pointer;
      padding: 29px 44px;
      @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        padding: 22px 20px;
      }
      @media only screen and (max-width: 1023px) {
        padding: 22px 0;
      }
      @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
        align-items: flex-end;
        padding: 0;
      }

      &.active {
        .details img {
          transform: rotate(0deg) !important;
        }
      }

      .staking-flex {
        @include flex;
        width: 100%;
        @media only screen and (max-width: 767px) {
          width: 50%;
        }

        .title {
          margin-left: 15px;
          @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            margin-left: 10px;
          }
          @media only screen and (max-width: 767px) {
            margin-left: 10px;
          }
          @media only screen and (max-width: 350px) {
            margin-left: 0;
          }
        }

        .image {
          @media only screen and (max-width: 767px) {
            width: 20px;
          }
          @media only screen and (max-width: 350px) {
            display: none;
          }
        }

        &.details {
          width: fit-content !important;
          justify-content: flex-end;
          @media only screen and (max-width: 767px) {
            justify-content: unset;
          }

          .title {
            margin-left: 0;
          }

          .icon {
            transform: rotate(180deg);
            transition: all .4s ease;
            margin-left: 15px;
          }
        }
      }

      .interest, .balance {
        @media only screen and (max-width: 767px) {
          margin: 20px 0;
        }

      }

      .value-container {
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          &:nth-of-type(3) {
            display: none;
          }
        }
        @media only screen and (max-width: 767px) {
          width: 50%;
        }
      }
    }

    .staking-item-content {
      .upper-content {
        position: relative;
        padding: 19px 44px;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
          padding: 19px 20px;
        }
        @media only screen and (max-width: 1023px) {
          padding: 19px 0;
        }
        @media only screen and (max-width: 767px) {
          margin-top: 15px;
          border-top: 1px solid #304481;
        }

        .upper-background {
          position: absolute;
          background: linear-gradient(90deg, #2D8188 -9.88%, rgba(67, 142, 255, 0) 92.22%);
          opacity: 0.41;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          @media only screen and (max-width: 1023px) {
            display: none;
          }
        }

        .upper-content-container {
          position: relative;
          z-index: 1;
          @include space-between;
          @media only screen and (max-width: 767px) {
            display: block;
          }

          .title, .month {
            color: $base-color !important;
          }

          .upper-left {
            width: 25%;
            min-width: 250px;
            @include space-between;
            @media only screen and (max-width: 767px) {
              width: 100%;
              min-width: unset;
            }
          }

          .button-container {
            margin-top: 0;
          }

          ::v-deep .buttons-container {
            @include flex;

            @media only screen and (max-width: 767px) {
              display: block;
            }

            button, .button-container {
              margin-left: 18px;
              @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                margin-left: 10px;
              }

              &:first-child {
                margin-left: 0;
              }

              @media only screen and (max-width: 767px) {
                @include center-children;
                margin-left: 0;
              }
            }

            .button-container-content {
              @media only screen and (max-width: 1800px) and (min-width: 1366px) {
                width: 160px;
              }
              @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                width: 150px;
                height: 50px;
              }
              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            }
          }
        }


      }

      .down-content {
        padding: 29px 44px;
        @include space-between;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
          padding: 22px 20px;
        }
        @media only screen and (max-width: 1023px) {
          padding: 22px 0;
        }
        @media only screen and (max-width: 767px) {
          display: none;
        }

        .down-content-item {
          padding: 0 10px;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        .header-title {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          color: #FFFFFF;
        }

        .description {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          color: $base-color;
          margin-top: 10px;
          @media only screen and (max-width: 1800px) and (min-width: 1366px) {
            font-size: 18px;
          }
          @media only screen and (max-width: 1365px) and (min-width: 768px) {
            font-size: 16px;
            margin-top: 7px;
          }

        }
      }
    }
  }


}
</style>